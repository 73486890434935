import axios from "axios";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";

import { nonPersistentStore } from "../../store/store.js";
const sharedModule = require("../../shared.js").default;
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { photoStorageUrl } = sharedModule;

const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let hoursString = `${hours}`;
  if (hoursString.length === 1) {
    hoursString = "0" + hoursString;
  }
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hoursString + ":" + minutes + " " + ampm.toUpperCase();
  return strTime;
};

const convertUTCDateToLocalDate = (dateString) => {
  const date = new Date(dateString);
  return date;
};

const uploadFileToGCP = async (path, file) => {
  const storage = getStorage();
  const ref1 = ref(storage, path);
  const uploadTask = uploadBytesResumable(ref1, file, {
    contentType: "auto",
  });
  const promise = new Promise((resolve, reject) => {
    uploadTask.on("state_changed", (snapshot) => {
      const { bytesTransferred, totalBytes } = snapshot;
      const progress = (bytesTransferred / totalBytes) * 100;
      nonPersistentStore.commit("setImageUploadProgress", progress);
      if (progress === 100) {
        resolve(true);
      }
    });
    uploadTask.catch((err) => {
      console.log(err);
      reject(false);
    });
  });
  return promise;
};

const getUrlForFilePath = (path) => {
  return path ? `${photoStorageUrl}/${path}` : "";
};

const hasSongPassForGigInLocalCache = (gigId) => {
  const songPass = nonPersistentStore.state.localSongPasses.find(
    (elem) => elem.gigId === gigId
  );
  if (songPass) {
    return true;
  }
  return false;
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passTest = re.test(String(email).toLowerCase());
  return passTest;
};

const validatePhoneNumber = (phone) => {
  const re = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/;
  const passTest = re.test(String(phone).toLowerCase());
  return passTest;
};

// format functions

const formatBand = (band) => {
  band.createdAt = band.createdAt
    ? convertUTCDateToLocalDate(band.createdAt)
    : null;
  band.socials = band.socials
    ? band.socials.sort((a, b) => a.orderNum - b.orderNum)
    : [];
  return band;
};

const formatGig = (gig) => {
  gig.startDate = gig.startDate
    ? convertUTCDateToLocalDate(gig.startDate)
    : null;
  gig.endDate = gig.endDate ? convertUTCDateToLocalDate(gig.endDate) : null;
  gig.displayDate = gig.displayDate
    ? convertUTCDateToLocalDate(gig.displayDate)
    : null;
  gig.presets = gig.presets ? JSON.parse(gig.presets.replaceAll("'", "")) : [];
  gig.freeModeThankYouText = gig.freeModeThankYouText
    ? gig.freeModeThankYouText
    : "";
  gig.hasLandingPage =
    gig.allowSongPasses ||
    gig.allowPhotoTextShoutouts ||
    gig.allowOnlyTextShoutouts ||
    gig.allowOnlyPhotoShoutouts ||
    gig.hasExperiences ||
    gig.hasEvents ||
    gig.hasSongBattles
      ? true
      : false;

  if (gig?.authId === 602) {
    gig.hasLandingPage = false;
  }
  return gig;
};

const formatUser = (user) => {
  user.creationTime = user.creationTime
    ? convertUTCDateToLocalDate(user.creationTime)
    : null;
  return user;
};

const formatSong = (song) => {
  song.createdAt = song.createdAt
    ? convertUTCDateToLocalDate(song.createdAt)
    : null;
  if (song.minimum) {
    song.minimum =
      typeof song.minimum === "string" ? parseInt(song.minimum) : song.minimum;
  }
  return song;
};

const formatSetlist = (setlist) => {
  setlist.createdAt = setlist.createdAt
    ? convertUTCDateToLocalDate(setlist.createdAt)
    : null;
  setlist.songs = setlist.songs ? setlist.songs.map(formatSong) : [];
  return setlist;
};

const formatTip = (tip) => {
  tip.createdAt = tip.createdAt
    ? convertUTCDateToLocalDate(tip.createdAt)
    : null;
  if (tip.minimum) {
    tip.minimum =
      typeof tip.minimum === "string" ? parseInt(tip.minimum) : tip.minimum;
  }
  return tip;
};

const formatTipsGroupedForGig = (obj) => {
  obj.tips = obj.tips.map(formatTip);
  obj.requests = obj.requests.map((tip) => {
    tip.firstVoted = tip.firstVoted
      ? convertUTCDateToLocalDate(tip.firstVoted)
      : null;
    tip.lastVoted = tip.lastVoted
      ? convertUTCDateToLocalDate(tip.lastVoted)
      : null;
    if (tip.minimum) {
      tip.minimum =
        typeof tip.minimum === "string" ? parseInt(tip.minimum) : tip.minimum;
    }
    return tip;
  });
  return obj;
};

const formatEmailListItem = (emailList) => {
  emailList.createdAt = emailList.createdAt
    ? convertUTCDateToLocalDate(emailList.createdAt)
    : null;
  emailList.amount_earned = emailList.amount_earned
    ? emailList.amount_earned / 100.0
    : 0;
  return emailList;
};

const formatFAQ = (faq) => {
  return faq;
};

const formatPayout = (payout) => {
  payout.createdAt = payout.createdAt
    ? convertUTCDateToLocalDate(payout.createdAt)
    : null;
  return payout;
};

const formatStage = (stage) => {
  return stage;
};

const formatVenue = (venue) => {
  if (venue.stages) {
    venue.stages = venue.stages.map(formatStage);
  }
  return venue;
};

const formatEventOption = (eventOption) => {
  return eventOption;
};

const formatEvent = (event) => {
  if (event.options) {
    event.options = event.options.map(formatEventOption);
  }
  return event;
};

const formatEventTransaction = (eventTransaction) => {
  eventTransaction.createdAt = eventTransaction.createdAt
    ? convertUTCDateToLocalDate(eventTransaction.createdAt)
    : null;
  return eventTransaction;
};

const formatPostShowSurvey = (postShowSurvey) => {
  return postShowSurvey;
};

const formatBandTip = (bandTip) => {
  bandTip.createdAt = bandTip.createdAt
    ? convertUTCDateToLocalDate(bandTip.createdAt)
    : null;
  return bandTip;
};

const formatCustomer = (customer) => {
  return customer;
};

const formatFollower = (follower) => {
  follower.creationTime = follower.creationTime
    ? convertUTCDateToLocalDate(follower.creationTime)
    : null;
  return follower;
};

const formatResetSong = (resetSong) => {
  return resetSong;
};

const formatSongPass = (songPass) => {
  songPass.createdAt = songPass.createdAt
    ? convertUTCDateToLocalDate(songPass.createdAt)
    : null;
  return songPass;
};

const formatShoutout = (shoutout) => {
  shoutout.createdAt = shoutout.createdAt
    ? convertUTCDateToLocalDate(shoutout.createdAt)
    : null;
  return shoutout;
};

const formatExperience = (experience) => {
  experience.createdAt = experience.createdAt
    ? convertUTCDateToLocalDate(experience.createdAt)
    : null;
  return experience;
};

const formatGigExperience = (gigExperience) => {
  gigExperience.startDate = gigExperience.startDate
    ? convertUTCDateToLocalDate(gigExperience.startDate)
    : null;
  return gigExperience;
};

const formatExperienceTransaction = (experienceTransaction) => {
  experienceTransaction.createdAt = experienceTransaction.createdAt
    ? convertUTCDateToLocalDate(experienceTransaction.createdAt)
    : null;
  experienceTransaction.paymentCollectedAt =
    experienceTransaction.paymentCollectedAt
      ? convertUTCDateToLocalDate(experienceTransaction.paymentCollectedAt)
      : null;
  return experienceTransaction;
};

const formatExperienceTransactionsGroupedForGig = (obj) => {
  if (obj) {
    const out = {};

    for (const key in obj) {
      if (key === "gigId") {
        out[key] = obj[key];
      } else {
        const objKeys = Object.keys(obj[key]);
        const inner = {};
        for (const objKey of objKeys) {
          inner[objKey] = obj[key][objKey].map(formatExperienceTransaction);
        }
        out[key] = inner;
      }
    }
    return out;
  } else {
    return null;
  }
};

const formatMerchandise = (merchandise) => {
  merchandise.createdAt = merchandise.createdAt
    ? convertUTCDateToLocalDate(merchandise.createdAt)
    : null;
  return merchandise;
};

const formatMerchandiseTransaction = (merchandiseTransaction) => {
  merchandiseTransaction.createdAt = merchandiseTransaction.createdAt
    ? convertUTCDateToLocalDate(merchandiseTransaction.createdAt)
    : null;
  return merchandiseTransaction;
};

const formatSongBattle = (songBattle) => {
  songBattle.createdAt = songBattle.createdAt
    ? convertUTCDateToLocalDate(songBattle.createdAt)
    : null;
  return songBattle;
};

const formatGigSongBattle = (gigSongBattle) => {
  gigSongBattle.createdAt = gigSongBattle.createdAt
    ? convertUTCDateToLocalDate(gigSongBattle.createdAt)
    : null;
  return gigSongBattle;
};

const formatSongBattleTransaction = (songBattleTransaction) => {
  songBattleTransaction.createdAt = songBattleTransaction.createdAt
    ? convertUTCDateToLocalDate(songBattleTransaction.createdAt)
    : null;
  return songBattleTransaction;
};

const getCurrencySymbol = (currency) => {
  const upper = currency.toUpperCase();
  return nonPersistentStore.state.currencyOptions.find(
    (option) => option.text === upper
  ).symbol;
};

const getCurrencyConversionRate = async (from, to) => {
  try {
    const endpoint = `${baseUrl}/currency/conversion-rate?from=${from}&to=${to}`;
    const response = await axios.get(endpoint);
    const axiosData = response.data;
    return axiosData;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const convertCurrencyObjects = async (objects, objectKey, from, to) => {
  try {
    const endpoint = `${baseUrl}/currency/conversion-rate?from=${from}&to=${to}`;
    const response = await axios.get(endpoint);
    const axiosData = response.data;
    const { rate } = axiosData;
    const rateToDecimal = rate / 100.0;
    return objects.map((elem) => {
      elem[`${objectKey}_converted`] = Math.round(
        elem[objectKey] * rateToDecimal
      );
      return elem;
    });
  } catch (err) {
    console.log(err);
    return false;
  }
};

const checkIfStringIsInteger = (str) => {
  const regexCheck = new RegExp("^[0-9]+$");
  return regexCheck.test(str);
};

const checkIfStringIsDecimal = (str) => {
  const regexCheckForDecimal = new RegExp("^[0-9]+([.][0-9]+)?$");
  return regexCheckForDecimal.test(str);
};

export {
  // time formatting
  formatAMPM,
  // uploading files
  uploadFileToGCP,
  getUrlForFilePath,
  hasSongPassForGigInLocalCache,
  // formatting methods
  formatBand,
  formatGig,
  formatUser,
  formatSetlist,
  formatSong,
  formatTip,
  formatTipsGroupedForGig,
  formatEmailListItem,
  formatFAQ,
  formatPayout,
  formatVenue,
  formatStage,
  formatEventOption,
  formatEvent,
  formatEventTransaction,
  formatPostShowSurvey,
  formatBandTip,
  formatCustomer,
  formatResetSong,
  formatSongPass,
  formatShoutout,
  formatExperience,
  formatGigExperience,
  formatExperienceTransaction,
  formatExperienceTransactionsGroupedForGig,
  formatMerchandise,
  formatMerchandiseTransaction,
  formatSongBattle,
  formatSongBattleTransaction,
  formatGigSongBattle,
  formatFollower,
  // currency stuff
  getCurrencySymbol,
  convertCurrencyObjects,
  getCurrencyConversionRate,
  // random
  validateEmail,
  validatePhoneNumber,
  checkIfStringIsDecimal,
  checkIfStringIsInteger,
};
