import axios from "axios";

import {
  formatExperience,
  formatExperienceTransaction,
  formatExperienceTransactionsGroupedForGig,
  formatGigExperience,
} from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

const getExperience = async (req) => {
  try {
    if (!req) {
      throw new Error("No request provided");
    }
    const { ID } = req;

    if (!ID) {
      throw new Error("No ID provided");
    }

    const axiosEndpoint = `${baseUrl}/experience?ID=${ID}`;
    const response = await axios.get(axiosEndpoint);
    const experience = response.data;
    const formattedExperience = formatExperience(experience);

    return formattedExperience;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getExperiences = async (req) => {
  try {
    if (!req) {
      throw new Error("No request provided");
    }
    const { bandId } = req;

    if (!bandId) {
      throw new Error("No bandId provided");
    }

    let axiosEndpoint = `${baseUrl}/experiences?bandId=${bandId}`;

    const response = await axios.get(axiosEndpoint);
    const experiences = response.data;
    const formattedExperiences =
      experiences?.length > 0
        ? experiences.map((experience) => formatExperience(experience))
        : [];

    return formattedExperiences.sort((b, a) => a.createdAt - b.createdAt);
  } catch (err) {
    console.log(err);
    return [];
  }
};

const createExperience = async (req) => {
  try {
    if (!req) {
      throw new Error("No request provided");
    }
    const axiosEndpoint = `${baseUrl}/experience`;
    const response = await axios.post(axiosEndpoint, req);
    const experience = response.data;
    const formattedExperience = formatExperience(experience);
    return formattedExperience;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const updateExperience = async (req) => {
  try {
    if (!req) {
      throw new Error("No request provided");
    }

    const { experienceId, updateData } = req;

    if (!experienceId) {
      throw new Error("No experienceId provided");
    }
    if (!updateData) {
      throw new Error("No updateData provided");
    }

    const axiosEndpoint = `${baseUrl}/experience`;
    const response = await axios.patch(axiosEndpoint, {
      ID: experienceId,
      ...updateData,
    });
    const experience = response.data;
    const formattedExperience = formatExperience(experience);

    return formattedExperience;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const deleteExperience = async (req) => {
  try {
    if (!req) {
      throw new Error("No request provided");
    }

    const { experienceId } = req;

    if (!experienceId) {
      throw new Error("No experienceId provided");
    }

    const axiosEndpoint = `${baseUrl}/experience`;
    await axios.delete(axiosEndpoint, { ID: experienceId });

    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getExperiencesForGig = async (gigId, bandId, forceRefresh = false) => {
  try {
    if (!gigId || !bandId) {
      throw new Error("No gigId or bandId provided");
    }
    const experiencesInStore = state.currentGigExperiences;

    if (experiencesInStore?.gigId === gigId && !forceRefresh) {
      return experiencesInStore.values;
    }

    let axiosEndpoint = `${baseUrl}/gig-experiences?gigId=${gigId}&bandId=${bandId}`;

    if (forceRefresh) {
      axiosEndpoint += `&ignoreCache=true&initiatedAt=${new Date().toJSON()}`;
    }

    const response = await axios.get(axiosEndpoint);
    const gigExperiences = response.data;
    const formattedGigExperiences =
      gigExperiences?.length > 0
        ? gigExperiences.map((gigExperience) =>
            formatGigExperience(gigExperience)
          )
        : [];

    nonPersistentStore.commit("addOrUpdateGigExperiencesData", {
      gigId,
      bandId,
      values: formattedGigExperiences,
    });

    return formattedGigExperiences;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const addExperienceToGig = async (req) => {
  try {
    if (!req) {
      throw new Error("No request provided");
    }
    const { gigId, experienceId, bandId } = req;

    if (!gigId) {
      throw new Error("No gigId provided");
    }
    if (!experienceId) {
      throw new Error("No experienceId provided");
    }

    if (!bandId) {
      throw new Error("No bandId provided");
    }

    const axiosEndpoint = `${baseUrl}/gig-experience`;
    const gigExperienceData = {
      authId: bandId,
      bandId,
      experienceId,
      gigId,
      hidden: false,
      paused: false,
      startTime: new Date().toJSON(),
    };
    const response = await axios.post(axiosEndpoint, gigExperienceData);

    const gigExperience = response.data;
    const formattedGigExperience = formatGigExperience(gigExperience);

    return formattedGigExperience;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const removeExperienceFromGig = async (req) => {
  try {
    if (!req) {
      throw new Error("No request provided");
    }

    const { gigExperienceId } = req;

    if (!gigExperienceId) {
      throw new Error("No gigExperienceId provided");
    }

    const axiosEndpoint = `${baseUrl}/gig-experience`;
    await axios.patch(axiosEndpoint, { ID: gigExperienceId, hidden: true });

    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getGroupedExperienceTransactionsForGig = async (req) => {
  try {
    if (!req) {
      throw new Error("No request provided");
    }
    const { gigId, forceRefresh } = req;

    if (!gigId) {
      throw new Error("No bandId nor gigId provided");
    }

    const experienceTransactionsInStore =
      state.currentGigExperiencesTransactions;
    if (experienceTransactionsInStore?.gigId === gigId && !forceRefresh) {
      return experienceTransactionsInStore.values;
    }

    let axiosEndpoint = `${baseUrl}/experience-transactions/grouped?gigId=${gigId}`;

    if (forceRefresh) {
      axiosEndpoint += `&initiatedAt=${new Date().toJSON()}`;
    }

    const response = await axios.get(axiosEndpoint);
    const experienceObj = response.data;
    if (experienceObj?.gigId) {
      const formattedObj =
        formatExperienceTransactionsGroupedForGig(experienceObj);

      nonPersistentStore.commit("addOrUpdateGigExperiencesTransactionsData", {
        gigId,
        values: formattedObj,
      });

      return formattedObj;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getLastPurchaseForGigExperience = async (req) => {
  try {
    if (!req) {
      throw new Error("No request provided");
    }
    const uniqueSessionId = state.uniqueId;
    const { gigExperienceId } = req;

    if (!gigExperienceId) {
      throw new Error("No gigExperienceId provided");
    }

    const axiosEndpoint = `${baseUrl}/experience-transaction?gigExperienceId=${gigExperienceId}&uniqueSessionId=${uniqueSessionId}`;
    const response = await axios.get(axiosEndpoint);
    const experienceTransaction = response.data;
    if (experienceTransaction) {
      const formattedExperienceTransaction = formatExperienceTransaction(
        experienceTransaction
      );
      return formattedExperienceTransaction;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

const updateGigExperience = async (req) => {
  try {
    if (!req) {
      throw new Error("No request provided");
    }
    const { gigExperienceId, updateData } = req;

    if (!gigExperienceId) {
      throw new Error("No gigExperienceId provided");
    }
    if (!updateData) {
      throw new Error("No updateData provided");
    }
    const axiosEndpoint = `${baseUrl}/gig-experience`;
    await axios.patch(axiosEndpoint, {
      ID: gigExperienceId,
      ...updateData,
    });

    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getExperienceTransactionsForGig = async (gigId) => {
  try {
    const axiosEndpoint = `${baseUrl}/experience-transactions?gigId=${gigId}`;
    const response = await axios.get(axiosEndpoint);
    const experienceTransactions = response.data;
    const formattedExperienceTransactions =
      experienceTransactions?.length > 0
        ? experienceTransactions.map((experienceTransaction) =>
            formatExperienceTransaction(experienceTransaction)
          )
        : [];
    return formattedExperienceTransactions;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export {
  getExperience,
  getExperiences,
  createExperience,
  updateExperience,
  deleteExperience,
  addExperienceToGig,
  removeExperienceFromGig,
  getExperiencesForGig,
  getGroupedExperienceTransactionsForGig,
  getLastPurchaseForGigExperience,
  updateGigExperience,
  getExperienceTransactionsForGig,
};
