import axios from "axios";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

const getSearchPage = async (req) => {
  try {
    const { pageName, ID } = req;
    if (!pageName && !ID) {
      throw new Error("No pageName or ID provided");
    }
    let axiosEndpoint = `${baseUrl}/search-page?`;

    if (pageName) {
      axiosEndpoint += `pageName=${pageName}`;
    } else if (ID) {
      axiosEndpoint += `ID=${ID}`;
    }
    const axiosResponse = await axios.get(axiosEndpoint);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const getAllSearchPages = async () => {};

const createSearchPage = async () => {};

const updateSearchPage = async () => {};

const deleteSearchPage = async () => {};

export {
  getSearchPage,
  getAllSearchPages,
  createSearchPage,
  updateSearchPage,
  deleteSearchPage,
};
