import axios from "axios";

import { formatStage, formatVenue } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

/**
 * description: gets a venue object based on id
 *
 * @param {string} place_id The id of a venue
 *
 * @return { Venue } returns a venue object if a matching one exists or undefined if not
 */
const getVenue = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object passed into method");
    }
    const { place_id, ID, pageName } = req;

    if (!place_id && !ID && !pageName) {
      throw new Error("No parameters passed into request object");
    }

    let venueUrl;

    if (place_id) {
      venueUrl = `${baseUrl}/venue?placeId=${place_id}`;
    } else if (ID) {
      venueUrl = `${baseUrl}/venue?ID=${ID}`;
    } else if (pageName) {
      venueUrl = `${baseUrl}/venue?pageName=${pageName}`;
    }

    const axiosResponse = await axios.get(venueUrl);
    const { data } = axiosResponse;

    return formatVenue(data);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
/**
 * description: gets all venue objects
 *
 *
 * @return { Venue[] } returns a venues array if a matching one exists or [] if not
 */
const getAllVenues = async () => {
  try {
    const venuesEndpoint = `${baseUrl}/venues?all=true`;
    const axiosResponse = await axios.get(venuesEndpoint);
    const { data } = axiosResponse;

    return data.map(formatVenue);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const updateVenue = async (venueId, updateData) => {
  try {
    if (!venueId) {
      throw "No venueId passed into method";
    }

    updateData.ID = venueId;

    const venueEndpoint = `${baseUrl}/venue`;
    await axios.patch(venueEndpoint, updateData);

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const addStageToVenue = async (venueId, stageData) => {
  try {
    if (!venueId) {
      throw new Error("No venueId passed into method");
    }
    stageData.venueId = venueId;
    const stageEndpoint = `${baseUrl}/stage`;
    const axiosResponse = await axios.post(stageEndpoint, stageData);
    const { data } = axiosResponse;

    return formatStage(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateStageAtVenue = async (stageId, stageData) => {
  try {
    if (!stageId) {
      throw new Error("No stageId passed into method");
    }
    const stageEndpoint = `${baseUrl}/stage`;
    await axios.patch(stageEndpoint, stageData);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const deleteStageFromVenue = async (stageId) => {
  try {
    if (!stageId) {
      throw new Error("No stageId passed into method");
    }
    const stageEndpoint = `${baseUrl}/stage`;
    await axios.delete(stageEndpoint, { ID: stageId });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

// to be fixed later

/**
 * description: gets all the shows from a venue
 *
 * @return { gigs[] } returns an array of gigs if a matching one exists or [] if not
 */
const getAllVenueShowsForDash = async () => {
  return [];
};

export {
  getVenue,
  getAllVenues,
  getAllVenueShowsForDash,
  updateVenue,
  addStageToVenue,
  updateStageAtVenue,
  deleteStageFromVenue,
};
