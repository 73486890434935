import axios from "axios";

import { formatFAQ } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");

const getAllFaqs = async () => {
  try {
    const faqEndpoint = `${baseUrl}/faqs?all=true`;
    const axiosResponse = await axios.get(faqEndpoint);
    const { data } = axiosResponse;
    return data.map(formatFAQ);
  } catch (err) {
    console.log(err);
    return [];
  }
};

const addFaq = async (title) => {
  try {
    const faqEndpoint = `${baseUrl}/faq`;
    const obj = {
      title,
      response: "",
      hidden: true,
      userId: nonPersistentStore.state.currentFirebaseUser.uid,
    };
    const axiosResponse = await axios.post(faqEndpoint, obj);
    const { data } = axiosResponse;
    return formatFAQ(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateFaq = async (faqId, data) => {
  try {
    if (!faqId) {
      throw new Error("No faqId provided");
    }
    // set hidden false
    data.hidden = false;
    data.ID = faqId;

    const faqEndpoint = `${baseUrl}/faq`;
    await axios.patch(faqEndpoint, data);

    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export { getAllFaqs, addFaq, updateFaq };
