import axios from "axios";

import {
  formatMerchandise,
  formatMerchandiseTransaction,
} from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

const getMerchandise = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object provided");
    }
    const { bandId, ID, forceRefresh } = req;

    if (bandId) {
      const valueInStore = state.currentMerchandiseForBand;

      if (valueInStore && valueInStore.bandId === bandId && !forceRefresh) {
        return valueInStore.values;
      }

      const axiosEndpoint = `${baseUrl}/merchandise?bandId=${bandId}`;
      const response = await axios.get(axiosEndpoint);
      const merchandiseData = response.data;
      const formattedMerchandise = merchandiseData
        ? merchandiseData.map(formatMerchandise)
        : [];

      nonPersistentStore.commit("addOrUpdateMerchandiseDataForBand", {
        bandId,
        values: formattedMerchandise,
      });
      return formattedMerchandise;
    } else if (ID) {
      const valueInStore = state.currentMerchandise;
      if (valueInStore?.ID === ID && !forceRefresh) {
        return valueInStore;
      }
      const axiosEndpoint = `${baseUrl}/merchandise?ID=${ID}`;
      const response = await axios.get(axiosEndpoint);
      const merchandiseData = response.data;
      const formattedMerchandise = merchandiseData
        ? formatMerchandise(merchandiseData)
        : null;
      nonPersistentStore.commit(
        "addOrUpdateMerchandiseData",
        formattedMerchandise
      );
      return formattedMerchandise;
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getMerchandiseTransactions = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object provided");
    }
    const { bandId, forceRefresh } = req;

    if (bandId) {
      const valueInStore = state.currentMerchandiseTransactions;

      if (valueInStore && valueInStore.bandId === bandId && !forceRefresh) {
        return valueInStore.values;
      }

      const axiosEndpoint = `${baseUrl}/merchandise-transactions?bandId=${bandId}`;
      const response = await axios.get(axiosEndpoint);
      const merchandiseData = response.data;
      const formattedMerchandise = merchandiseData
        ? merchandiseData.map(formatMerchandiseTransaction)
        : [];

      nonPersistentStore.commit("addOrUpdateMerchandiseTransactionsData", {
        bandId,
        values: formattedMerchandise,
      });

      return formattedMerchandise;
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getMerchandiseTransaction = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object provided");
    }
    const { uniqueSessionId } = req;

    if (uniqueSessionId) {
      const axiosEndpoint = `${baseUrl}/merchandise-transaction?uniqueSessionId=${uniqueSessionId}`;
      const response = await axios.get(axiosEndpoint);
      const merchandiseData = response.data;
      const formattedMerchandise = merchandiseData
        ? formatMerchandiseTransaction(merchandiseData)
        : null;

      return formattedMerchandise;
    } else {
      throw new Error("No uniqueSessionId provided");
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

const updateMerchandise = async (merchandiseId, merchandiseObj) => {
  try {
    if (!merchandiseId) {
      throw new Error("No merchandiseId provided");
    }
    merchandiseObj.ID = merchandiseId;
    const axiosEndpoint = `${baseUrl}/merchandise`;
    const response = await axios.patch(axiosEndpoint, merchandiseObj);
    const merchandiseData = response.data;
    const formattedMerchandise = merchandiseData
      ? formatMerchandise(merchandiseData)
      : null;
    return formattedMerchandise;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const deleteMerchandise = async (merchandiseId) => {
  try {
    await updateMerchandise(merchandiseId, { hidden: true });
  } catch (err) {
    console.log(err);
    return false;
  }
};

const createMerchandise = async (merchandiseObj) => {
  try {
    const axiosEndpoint = `${baseUrl}/merchandise`;
    const response = await axios.post(axiosEndpoint, merchandiseObj);
    const merchandiseData = response.data;
    const formattedMerchandise = merchandiseData
      ? formatMerchandise(merchandiseData)
      : null;
    return formattedMerchandise;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const addOptionToMerchandise = async (merchandiseId, merchandiseOptionObj) => {
  try {
    if (!merchandiseId) {
      throw new Error("No merchandiseId provided");
    }
    merchandiseOptionObj.merchandiseId = merchandiseId;
    const axiosEndpoint = `${baseUrl}/merchandise-option`;
    const response = await axios.post(axiosEndpoint, merchandiseOptionObj);
    const merchandiseData = response.data;
    const formattedMerchandise = merchandiseData
      ? formatMerchandise(merchandiseData)
      : null;
    return formattedMerchandise;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const removeOptionFromMerchandise = async (
  merchandiseId,
  merchandiseOptionId
) => {
  try {
    if (!merchandiseId) {
      throw new Error("No merchandiseId provided");
    }
    const axiosEndpoint = `${baseUrl}/merchandise-option`;
    const response = await axios.delete(axiosEndpoint, {
      merchandiseId,
      ID: merchandiseOptionId,
    });
    const merchandiseResponse = response.data;
    const formattedMerchandise = merchandiseResponse
      ? formatMerchandise(merchandiseResponse)
      : null;
    return formattedMerchandise;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateMerchandiseOption = async (
  merchandiseId,
  merchandiseOptionId,
  merchandiseOptionObj
) => {
  try {
    if (!merchandiseId) {
      throw new Error("No merchandiseId provided");
    }
    merchandiseOptionObj.merchandiseId = merchandiseId;
    merchandiseOptionObj.ID = merchandiseOptionId;
    const axiosEndpoint = `${baseUrl}/merchandise-option`;
    const response = await axios.patch(axiosEndpoint, merchandiseOptionObj);
    const merchandiseData = response.data;
    const formattedMerchandise = merchandiseData
      ? formatMerchandise(merchandiseData)
      : null;
    return formattedMerchandise;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateMerchandiseImage = async (
  merchandiseId,
  merchandiseImageId,
  merchandiseImageObj
) => {
  try {
    if (!merchandiseId) {
      throw new Error("No merchandiseId provided");
    }
    merchandiseImageObj.merchandiseId = merchandiseId;
    merchandiseImageObj.ID = merchandiseImageId;
    const axiosEndpoint = `${baseUrl}/merchandise-image`;
    const response = await axios.patch(axiosEndpoint, merchandiseImageObj);
    const merchandiseData = response.data;
    const formattedMerchandise = merchandiseData
      ? formatMerchandise(merchandiseData)
      : null;
    return formattedMerchandise;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateMerchandiseTransaction = async (merchandiseOptionObj) => {
  try {
    if (!merchandiseOptionObj?.ID) {
      throw new Error("No ID provided");
    }
    const axiosEndpoint = `${baseUrl}/merchandise-transaction`;
    const response = await axios.patch(axiosEndpoint, merchandiseOptionObj);
    const merchandiseData = response.data;
    const formattedMerchandise = merchandiseData
      ? formatMerchandise(merchandiseData)
      : null;
    return formattedMerchandise;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const addImageToMerchandise = async (merchandiseId, merchandiseImageObj) => {
  try {
    if (!merchandiseId) {
      throw new Error("No merchandiseId provided");
    }
    merchandiseImageObj.merchandiseId = merchandiseId;
    const axiosEndpoint = `${baseUrl}/merchandise-image`;
    const response = await axios.post(axiosEndpoint, merchandiseImageObj);
    const merchandiseData = response.data;
    const formattedMerchandise = merchandiseData
      ? formatMerchandise(merchandiseData)
      : null;
    return formattedMerchandise;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const removeImageFromMerchandise = async (
  merchandiseId,
  merchandiseImageId
) => {
  try {
    if (!merchandiseId) {
      throw new Error("No merchandiseId provided");
    }
    const axiosEndpoint = `${baseUrl}/merchandise-image`;
    const response = await axios.delete(axiosEndpoint, {
      merchandiseId,
      ID: merchandiseImageId,
    });
    const merchandiseResponse = response.data;
    const formattedMerchandise = merchandiseResponse
      ? formatMerchandise(merchandiseResponse)
      : null;
    return formattedMerchandise;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateMultipleMerchandiseOptions = async (merchandiseId, options) => {
  try {
    if (!merchandiseId) {
      throw new Error("No merchandiseId provided");
    }
    if (!options?.length) {
      throw new Error("No options provided");
    }

    const axiosEndpoint = `${baseUrl}/merchandise-options`;
    const response = await axios.patch(axiosEndpoint, {
      merchandiseId,
      options,
    });
    const merchandiseData = response.data;
    const formattedMerchandise = merchandiseData
      ? formatMerchandise(merchandiseData)
      : null;
    return formattedMerchandise;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export {
  getMerchandise,
  getMerchandiseTransactions,
  updateMerchandise,
  deleteMerchandise,
  createMerchandise,
  addOptionToMerchandise,
  removeOptionFromMerchandise,
  updateMerchandiseOption,
  addImageToMerchandise,
  removeImageFromMerchandise,
  updateMerchandiseImage,
  getMerchandiseTransaction,
  updateMerchandiseTransaction,
  updateMultipleMerchandiseOptions,
};
