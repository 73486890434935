import axios from "axios";

import { formatGig } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

const handleHiddenAndDraftGigs = (
  list,
  showHidden = false,
  showDrafts = false
) => {
  if (!showHidden || showHidden === false) {
    list = list.filter((elem) => !elem.hidden || elem.hidden !== true);
  }
  if (!showDrafts || showDrafts === false) {
    list = list.filter((elem) => !elem.draft || elem.draft !== true);
  }
  return list;
};

const getMostRecentGigFromList = (
  list,
  showHidden = false,
  showDrafts = false
) => {
  // assemble a response
  const now = new Date();
  list = handleHiddenAndDraftGigs(list, showHidden, showDrafts);
  list = list.filter((elem) => elem.endDate && elem.endDate > now);
  list = list.sort((a, b) => a.startDate - b.startDate);
  return list && list.length > 0 ? list[0] : null;
};

const onlyUpcomingGigs = (gigs) => {
  const now = new Date();

  return gigs.filter((gig) => gig.endDate && gig.endDate > now);
};

/**
 * description: gets the most recent gig for a band
 *
 * Only one of the following params is required/will be used
 * @param {string} req.authId The authId of a band (unique)
 * @param {string} req.gigId The firestore docId of a gig (unique)
 *
 * @return { Gig } returns a gig object if a matching one exists or undefined if not
 */
const getGig = async (req) => {
  try {
    if (!req) {
      throw "No request object was provided to getGig";
    }
    const { gigId, authId, gigPageName, bandPageName } = req;

    let singleGig = false;
    let urlToQuery = "";

    if (gigId) {
      if (state.currentGig && state.currentGig.id === gigId) {
        return state.currentGig;
      }
      urlToQuery = `${baseUrl}/gig?ID=${gigId}`;
      singleGig = true;
    } else if (gigPageName && bandPageName) {
      urlToQuery = `${baseUrl}/gig?gigPageName=${gigPageName}&bandPageName=${bandPageName}`;
      singleGig = true;
    } else if (authId) {
      urlToQuery = `${baseUrl}/gigs?bandId=${authId}`;
    } else {
      throw new Error("No valid params were provided to getGig");
    }

    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;

    if (singleGig) {
      const dataHasKeys = data && Object.keys(data).length > 0;
      if (!dataHasKeys) {
        throw new Error("No gig was found with the provided params");
      }
      const myGig = formatGig(data);
      nonPersistentStore.commit("addOrUpdateGigData", myGig);

      return myGig;
    } else {
      let mapped = data.map(formatGig);

      return getMostRecentGigFromList(mapped);
    }
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const getGigAnalytics = async (gigId) => {
  try {
    let urlToQuery = `${baseUrl}/user-events?gigId=${gigId}`;

    if (!gigId) {
      throw new Error("Need to provide gigId to getGigAnalytics");
    }

    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;

    return data;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

/**
 * description: gets all gigs for a band
 *
 * Only one of the following will be used
 * @param {ref} req.authId The authId for the band
 *
 * @return { Gig[] } returns a gig array if a matching one exists or [] if not
 */
const getAllGigsForBand = async (
  req,
  showHidden = false,
  showDrafts = false
) => {
  try {
    if (!req) {
      throw "No request object was provided to getAllGigsForBand";
    }
    const { authId } = req;

    const axiosResponse = await axios.get(`${baseUrl}/gigs?bandId=${authId}`);
    const { data } = axiosResponse;
    let mapped = data.map(formatGig);

    return handleHiddenAndDraftGigs(mapped, showHidden, showDrafts);
  } catch (e) {
    console.error(e);
    return [];
  }
};

/**
 * description: gets all upcoming gigs for bands
 *
 * @return { Gig[] } returns a gig array if a matching one exists or [] if not
 */
const getAllGigs = async (showHidden = false, showDrafts = false) => {
  try {
    const axiosResponse = await axios.get(`${baseUrl}/gigs?endDate=true`);
    const { data } = axiosResponse;
    let mapped = data.map(formatGig);

    // assemble a response
    return handleHiddenAndDraftGigs(mapped, showHidden, showDrafts);
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getSouthbendGigs = async (showHidden = false, showDrafts = false) => {
  try {
    const axiosResponse = await axios.get(`${baseUrl}/gigs?southbend=true`);
    const { data } = axiosResponse;
    let mapped = data.map(formatGig);

    // assemble a response
    return handleHiddenAndDraftGigs(mapped, showHidden, showDrafts);
  } catch (e) {
    console.error(e);
    return [];
  }
};

/**
 * description: Gets the current gig at a venue
 *
 * @param {ref} req.place_id The google api place_id for the venue
 * @param {ref} req.stageId The index of the stage at the venue
 *
 * @return { Gig } Returns the current gig at a venue or undefined if there is not one
 */
const getCurrentGigAtVenue = async (place_id, stageId) => {
  try {
    const urlToQuery = `${baseUrl}/gigs?venueId=${place_id}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    const mapped = data.map(formatGig);
    const filtered = mapped.filter((gig) => gig.stage === stageId);
    return getMostRecentGigFromList(filtered);
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

/**
 * description: Gets all gigs taking place at a venue
 *
 * @param {ref} req.placeId The google api place_id for the venue
 *
 * @return { Gig[] } returns a gig array if a matching one exists or [] if not
 */
const getAllGigsForVenue = async (
  placeId,
  showHidden = false,
  showDrafts = false
) => {
  try {
    const urlToQuery = `${baseUrl}/gigs?venueId=${placeId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    const mapped = data.map(formatGig);
    const upcomingGigs = onlyUpcomingGigs(mapped);
    return handleHiddenAndDraftGigs(upcomingGigs, showHidden, showDrafts);
  } catch (e) {
    console.error(e);
    return [];
  }
};

/**
 * description: updates a gig object in firestore. Gets gig reference based on params below
 *     and then updates the document using a merge strategy using the data provided.
 *
 * @param {string} req.gigId The firestore docId of a gig (unique)
 * @param {object} req.data The JSON data that the band document will be updated with
 *
 * @return {boolean} returns true if update was successful or false if not
 */
const updateGig = async (req) => {
  try {
    if (!req || !req.data) {
      throw "No data found in request object or no request object";
    }
    const { ID } = req.data;

    req.data.presets = req.data.presets ? JSON.stringify(req.data.presets) : "";

    if (!ID) {
      throw new Error("No gig ID was provided to updateGig");
    }

    req.startDate = req.startDate ? req.startDate.toJSON() : "";
    req.endDate = req.endDate ? req.endDate.toJSON() : "";

    req.place_id = req.place_id ? req.place_id : null;

    const urlToQuery = `${baseUrl}/gig`;
    await axios.patch(urlToQuery, req.data);

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * description: creates a gig object in firestore.
 *
 * Only one of the following params is required/will be used
 * @param {object} req.data The JSON data that the band document will be created with
 *
 * @return {Gig} returns gig Object if creation was succesful or undefined
 */
const createGig = async (req) => {
  try {
    if (!req || !req.data) {
      throw "No data found in request object or no request object";
    }
    req.data.presets = req.data.presets ? JSON.stringify(req.data.presets) : "";

    req.startDate = req.startDate ? req.startDate.toJSON() : "";
    req.endDate = req.endDate ? req.endDate.toJSON() : "";

    req.place_id = req.place_id ? req.place_id : null;

    const urlToQuery = `${baseUrl}/gig`;
    const axiosResponse = await axios.post(urlToQuery, req.data);
    const { data } = axiosResponse;
    return formatGig(data);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

/**
 * description: deletes a gig object in firestore.
 *
 * Only one of the following params is required/will be used
 * @param {object} req.gigId The document id for the firebase doc to be deleted
 *
 * @return { boolean } returns true if operation was succesful or false
 */
const deleteGig = async (req) => {
  try {
    if (!req) {
      throw "No request object provided";
    }
    if (!req.gigId) {
      throw "No gigId in request object";
    }

    const urlToQuery = `${baseUrl}/gig`;
    await axios.patch(urlToQuery, {
      ID: req.gigId,
      hidden: true,
    });

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * description: Gets all gigs and formats them appropriately for the dashboard
 *
 * @return { Gig[] } returns a gig Array if operation was succesful or [] if not
 */
const getAllGigsForDash = async () => {
  try {
    const urlToQuery = `${baseUrl}/admin/all-gigs`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    const mapped = data.map(formatGig);

    const newEndpoint = `${baseUrl}/admin/gigs-stats`;
    const statsResponse = await axios.get(newEndpoint);
    const { data: stats } = statsResponse;

    const statsHash = {};
    for (const stat of stats) {
      statsHash[stat.gigId] = stat;
    }
    for (const gig of mapped) {
      gig.stats = statsHash[gig.ID] || {};
    }

    return mapped;
  } catch (err) {
    console.log(err);
    return [];
  }
};

/**
 * description: gets all gigs for a band
 *
 * @param {ref} req.lat The geolocation latitude
 * @param {ref} req.long The geolocation longitude
 *
 * @return { Gig[] } returns a gig array if a matching one exists or [] if not
 */
const getAllGigsNearby = async (req) => {
  try {
    if (!req) {
      throw "No request object was provided to getAllGigsNearby";
    }
    const { lat, lon } = req;
    if (!lat || !lon) {
      throw "No lat or lon provided to getAllGigsNearby";
    }

    const gigsUrl = `${baseUrl}/gigs?latitude=${lat}&longitude=${lon}`;
    const axiosResponse = await axios.get(gigsUrl);
    const { data } = axiosResponse;
    let mapped = data.map(formatGig);
    mapped = onlyUpcomingGigs(mapped);

    return handleHiddenAndDraftGigs(mapped, false, false);
  } catch (e) {
    console.error(e);
    return [];
  }
};

/**
 * description: Gets all gigs within a certain data range
 * // optional constrainsts
 * @param {req.startDate}: The javascript date object representing a startDate
 * @param {req.endDate}: The javascript date object representing an endDate
 *
 * @return { Gig[] } returns a gig array or [] if not
 */
const getAllGigsInDateRange = async (req) => {
  try {
    if (!req) {
      throw "No request object was provided to getAllGigsInDateRange";
    }
    return [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getStatsForAllGigs = async (bandId) => {
  try {
    if (!bandId) {
      throw new Error("No bandId provided to getStatsForAllGigs");
    }
    const urlToQuery = `${baseUrl}/gigs/stats?bandId=${bandId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getStatsForGig = async (gigId) => {
  try {
    if (!gigId) {
      throw new Error("No gigId provided to getStatsForAllGigs");
    }
    const urlToQuery = `${baseUrl}/gig/stats?ID=${gigId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export {
  getGig,
  getAllGigsForBand,
  getAllGigs,
  getCurrentGigAtVenue,
  getAllGigsForVenue,
  getAllGigsNearby,
  getAllGigsInDateRange,
  updateGig,
  createGig,
  deleteGig,
  getAllGigsForDash,
  getStatsForAllGigs,
  getStatsForGig,
  getSouthbendGigs,
  getGigAnalytics,
};
