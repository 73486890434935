import axios from "axios";

import { formatPostShowSurvey } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

// add survey results to firebase
const addNewSurvey = async (bandId, gigId, results) => {
  try {
    if (!bandId) {
      throw new Error("No bandId provided");
    }
    if (!gigId) {
      throw new Error("No gigId provided");
    }

    let reason = "";
    for (const key in results) {
      const value = results[key];
      if (value === true) {
        reason = key;
        break;
      } else if (key === "Other issue reason") {
        reason = results[key];
        break;
      }
    }

    const addData = {
      bandId,
      gigId,
      reason,
    };

    const surveyUrl = `${baseUrl}/post-show-survey`;
    const axiosResponse = await axios.post(surveyUrl, addData);
    const { data } = axiosResponse;
    return formatPostShowSurvey(data);
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

const getSurvey = async (gigId) => {
  try {
    if (!gigId) {
      throw new Error("No gigId provided");
    }

    const surveyUrl = `${baseUrl}/post-show-survey?gigId=${gigId}`;
    const axiosResponse = await axios.get(surveyUrl);
    const { data } = axiosResponse;

    return formatPostShowSurvey(data);
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getAllSurveysForBand = async (bandId) => {
  try {
    if (!bandId) {
      throw new Error("No bandId provided");
    }
    const surveyUrl = `${baseUrl}/post-show-surveys?bandId=${bandId}`;
    const axiosResponse = await axios.get(surveyUrl);
    const { data } = axiosResponse;

    return data.map(formatPostShowSurvey);
  } catch (err) {
    console.log(err);
    return [];
  }
};

export { getAllSurveysForBand, addNewSurvey, getSurvey };
