import { initializeApp } from "@firebase/app";
import { getAuth } from "firebase/auth";
import App from "./App.vue";
import "./theme.scss";
import Vue from "vue";
import router from "./router/router";
import { nonPersistentStore, persistentStore } from "./store/store";
import vuetify from "@/plugins/vuetify";
import { getUser } from "./services/firebaseService";
import { getAnalytics } from "firebase/analytics";
import axios from "axios";
import VueSocialSharing from "vue-social-sharing";
let app = "";
let sharedConstants;

const isToServer = (url) => {
  const { apiBaseUrl } = sharedConstants;
  return url.includes(apiBaseUrl);
};

// error handling
Vue.config.errorHandler = (err) => {
  console.log(err);
};

// shared constants
sharedConstants = require("./shared.js").default;
const { firestoreConfig } = sharedConstants;
sharedConstants.install = () => {
  Object.defineProperty(Vue.prototype, "$sharedConstants", {
    get() {
      return sharedConstants;
    },
  });
};
Vue.use(sharedConstants);

// add token to axios
const axiosOriginalGet = axios.get;
const newAxiosGet = async (...urlParams) => {
  const urlParam = urlParams[0];
  const currentFirebaseUser = nonPersistentStore.state.currentFirebaseUser;
  if (currentFirebaseUser && isToServer(urlParam)) {
    const accessToken = await currentFirebaseUser.getIdToken();
    if (urlParam.includes("?")) {
      urlParams[0] = `${urlParam}&authToken=${accessToken}`;
      if (sharedConstants?.socket?.id) {
        urlParams[0] += `&sid=${sharedConstants.socket.id}`;
      }
    } else {
      urlParams[0] = `${urlParam}?authToken=${accessToken}`;
      if (sharedConstants?.socket?.id) {
        urlParams[0] += `&sid=${sharedConstants.socket.id}`;
      }
    }
  } else {
    urlParams[0] = urlParam;
  }
  return await axiosOriginalGet(...urlParams);
};
axios.get = newAxiosGet;

const axiosOriginalPatch = axios.patch;
const newAxiosPatch = async (...req) => {
  const currentFirebaseUser = nonPersistentStore.state.currentFirebaseUser;
  if (currentFirebaseUser) {
    const accessToken = await currentFirebaseUser.getIdToken();
    if (sharedConstants?.socket?.id) {
      req[0] += `?sid=${sharedConstants.socket.id}`;
    }
    req[1].authToken = accessToken;
  }
  return await axiosOriginalPatch(...req);
};
axios.patch = newAxiosPatch;

const axiosOriginalPost = axios.post;
const newAxiosPost = async (...req) => {
  const currentFirebaseUser = nonPersistentStore.state.currentFirebaseUser;
  if (currentFirebaseUser) {
    const accessToken = await currentFirebaseUser.getIdToken();
    if (sharedConstants?.socket?.id) {
      req[0] += `?sid=${sharedConstants.socket.id}`;
    }
    req[1].authToken = accessToken;
  }
  return await axiosOriginalPost(...req);
};
axios.post = newAxiosPost;

const axiosOriginalDelete = axios.delete;
const newAxiosDelete = async (...req) => {
  const currentFirebaseUser = nonPersistentStore.state.currentFirebaseUser;
  if (currentFirebaseUser) {
    const accessToken = await currentFirebaseUser.getIdToken();
    if (sharedConstants?.socket?.id) {
      req[0] += `?sid=${sharedConstants.socket.id}`;
    }
    req[1].authToken = accessToken;
    req[1] = { data: req[1] };
  }
  return await axiosOriginalDelete(...req);
};
axios.delete = newAxiosDelete;

initializeApp(firestoreConfig);
getAnalytics();

getAuth().onAuthStateChanged((authImpl) => {
  const auth = authImpl && authImpl.auth ? authImpl.auth : undefined;
  const user = auth ? auth.currentUser : undefined;

  if (user) {
    user.getIdToken(true);
  }

  nonPersistentStore.commit("setCurrentFirebaseUser", user);

  // first occurence of app being loaded in a session
  if (!app) {
    const currentlyLoggedInBand =
      nonPersistentStore.state.currentlyLoggedInBand;
    if (currentlyLoggedInBand && currentlyLoggedInBand.firestore) {
      nonPersistentStore.commit("setCurrentlyLoggedInBand", null);
    }

    const currencyOptionFrompersistentStore =
      persistentStore.state.selectedCurrency;
    if (currencyOptionFrompersistentStore) {
      nonPersistentStore.commit(
        "setSelectedCurrency",
        currencyOptionFrompersistentStore
      );
    }

    Vue.use(VueSocialSharing);

    /* eslint-disable no-new */
    app = new Vue({
      router,
      nonPersistentStore,
      persistentStore,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");

    // see if there is already a unique id string in the persistent store
    const uniqueId = persistentStore.state.uniqueId;
    // if not, generate a new one and save it
    if (!uniqueId) {
      const newUniqueId = Math.random().toString(36).substring(2, 15);
      nonPersistentStore.commit("setUniqueId", newUniqueId);
    } else {
      nonPersistentStore.commit("setUniqueId", uniqueId);
    }

    window.analytics.identify(uniqueId);

    // see if there is a customerEmail in the persistent store
    const customerEmail = persistentStore.state.customerEmail;
    if (customerEmail) {
      nonPersistentStore.commit("setCustomerEmail", customerEmail);
    }
    const localSongPasses = persistentStore.state.localSongPasses;
    if (localSongPasses) {
      nonPersistentStore.commit("setLocalSongPasses", localSongPasses);
    }
    const hasBeenAskedForAge = persistentStore.state.hasBeenAskedForAge;
    if (hasBeenAskedForAge) {
      const isOver18 = persistentStore.state.isOver18;
      nonPersistentStore.commit("setIsOver18", isOver18);
    }
    const optIntoEmails = persistentStore.state.optIntoEmails;
    if (optIntoEmails) {
      nonPersistentStore.commit("setOptIntoEmails", optIntoEmails);
    }
    const customerName = persistentStore.state.customerName;
    if (customerName) {
      nonPersistentStore.commit("setCustomerName", customerName);
    }
    // see if any past gig stats exist
    const pastGigStats = persistentStore.state.userVotingForGigs;
    if (pastGigStats && Object.keys(pastGigStats).length > 0) {
      nonPersistentStore.commit("setUserVotingForGigs", pastGigStats);
    }
    if (user) {
      getUser({ uid: user.uid });
    }
  }
});
