import axios from "axios";

import { formatSong } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

/**
 * description: adds a song to a setlist
 *
 * @param {object} req.data The JSON data that the band document will be created with
 * @param {string} req.setlistId The id for the setlist to which the songs will be added
 *
 * @return { boolean } returns true if addition was succesful or false if not
 */
const addSong = async (req) => {
  try {
    if (!req || !req.data) {
      throw "No data found in request object or no request object";
    }
    if (!req.data.name) {
      throw "No name key found in data param";
    }
    if (!req.data.artist) {
      throw "No artist key found in data param";
    }
    if (!req.setlistId) {
      throw "No setlistId key passed to addSong";
    }

    req.data.setlistId = req.setlistId;

    const songEndpoint = `${baseUrl}/song`;
    const axiosResponse = await axios.post(songEndpoint, req.data);
    const { data } = axiosResponse;
    return formatSong(data);
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * description: adds a song to a setlist
 *
 * @param {object} req.songs The JSON data that the band document will be created with
 * @param {string} req.setlistId The id for the setlist to which the songs will be added
 *
 * @return { boolean } returns true if addition was succesful or false if not
 */
const addMultipleSongs = async (req) => {
  try {
    if (!req) {
      throw "No request object";
    }
    if (!req.songs) {
      throw new Error("no songs key found in request object");
    } else if (!req.setlistId) {
      throw "No setlistId key passed to addSong";
    }

    const songsEndpoint = `${baseUrl}/songs`;
    await axios.post(songsEndpoint, {
      songs: req.songs,
      setlistId: req.setlistId,
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * description: removes a song from a setlist
 *
 * @param {string} req.songId The id for the song which will be removed
 *
 * @return { boolean } returns true if removal was succesful or false if not
 */
const removeSong = async (req) => {
  try {
    if (!req) {
      throw "No request object";
    }
    if (!req.songId) {
      throw "No songId in request object";
    }

    const songEndpoint = `${baseUrl}/song`;
    await axios.delete(songEndpoint, { ID: req.songId });

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const removeMultipleSongs = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object passed to removeMultipleSongs");
    }
    const { songIds, setlistId } = req;
    if (!songIds) {
      throw new Error("No songIds key found in request object");
    }
    if (!setlistId) {
      throw new Error("No setlistId key found in request object");
    }
    const axiosEndpoint = `${baseUrl}/songs`;
    await axios.delete(axiosEndpoint, { songIds, setlistId });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

/**
 * description: removes a song from a setlist
 *
 * @param {object} req.data The JSON data that the band document will be updated with
 * @param {string} req.songId The id for the song which will be removed
 *
 * @return { boolean } returns true if removal was succesful or false if not
 */
const updateSong = async (req) => {
  try {
    if (!req || !req.data) {
      throw "No data found in request object or no request object";
    }
    const { songId } = req;

    if (!songId) {
      throw "No songId key passed to updateSong";
    }

    req.data.ID = songId;

    const songEndpoint = `${baseUrl}/song`;
    await axios.patch(songEndpoint, req.data);

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const searchSongs = async (songName, artist) => {
  try {
    const axiosEndpoint = `${baseUrl}/song/search?songName=${songName}&artist=${artist}`;
    const axiosResponse = await axios.get(axiosEndpoint);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export {
  addSong,
  addMultipleSongs,
  removeMultipleSongs,
  removeSong,
  updateSong,
  searchSongs,
};
