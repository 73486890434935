import axios from "axios";

import { formatBand } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

/**
 * description: gets a specific band based on params passed in
 *
 * Only one of the following params is required/will be used
 * @param {string} req.authId The authId of a band (unique)
 * @param {string} req.bandId The firestore docId of a band (unique)
 * @param {string} req.name The name of a band (unique)
 * @param {string} req.pageName The page-name for a band (unique)
 *
 * @return { Band } returns a band object if a matching one exists or undefined if not
 */
const getBand = async (req) => {
  try {
    if (!req) {
      throw "No request object was provided to getBand";
    }
    let { pageName, bandId, authId } = req;

    if (!authId && bandId) {
      authId = bandId;
    }

    if (authId) {
      if (state.currentBand && state.currentBand.ID === authId) {
        return state.currentBand;
      }
    }

    if (pageName) {
      if (state.currentBand && state.currentBand.pageName === pageName) {
        return state.currentBand;
      }
    }

    let urlToQuery = "";
    if (pageName) {
      urlToQuery = `${baseUrl}/band?pageName=${pageName}`;
    } else if (bandId) {
      urlToQuery = `${baseUrl}/band?ID=${bandId}`;
    } else if (authId) {
      urlToQuery = `${baseUrl}/band?ID=${authId}`;
    } else {
      throw "No valid params were provided to getBand";
    }

    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    if (data) {
      const formattedBand = formatBand(data);
      nonPersistentStore.commit("addOrUpdateBandData", formattedBand);
      return formatBand(data);
    } else {
      return null;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

/**
 * description: gets all Bands for a user
 *
 * @param {string} uid A user's id for which all bands associated with it will be returned
 *
 * @return { Band[] }
 */
const getAllBandsForUser = async (uid) => {
  try {
    if (!uid) {
      throw new Error("No uid provided to getAllBandsForUser");
    }
    const urlToQuery = `${baseUrl}/bands?uid=${uid}&inititatedAt=${new Date().toJSON()}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data.map(formatBand);
  } catch (err) {
    console.log(err);
    return [];
  }
};

/**
 * description: updates a band object in firestore. Gets band reference based on params below
 *     and then updates the document using a merge strategy using the data provided.
 *
 * Only one of the following params is required/will be used
 * @param {string} req.authId The authId of a band (unique)
 * @param {string} req.bandId The firestore docId of a band (unique)
 * @param {object} req.data The JSON data that the band document will be updated with
 * @param {string} req.name The name of a band (unique)
 * @param {string} req.pageName The page-name for a band (unique)
 *
 * @return {boolean} returns true if update was succesful or false if its not
 */
const updateBand = async (req) => {
  try {
    if (!req || !req.data) {
      throw "No data found in request object or no request object";
    }
    const { ID } = req.data;

    if (!ID) {
      throw new Error("No ID found in request data");
    }

    const urlToQuery = `${baseUrl}/band`;
    await axios.patch(urlToQuery, req.data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const deleteBand = async (req) => {
  try {
    if (!req) {
      throw "No request object";
    }
    const { ID } = req;
    if (!ID) {
      throw "No ID found in request object";
    }
    const urlToQuery = `${baseUrl}/band`;
    await axios.delete(urlToQuery, { ID });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const updateBandBetaStatus = async (req) => {
  try {
    if (!req) {
      throw "No request object";
    }
    const { ID, hasBetaFeatures } = req;
    if (!ID) {
      throw "No ID found in request object";
    }
    const urlToQuery = `${baseUrl}/band-make-beta`;
    await axios.patch(urlToQuery, { ID, hasBetaFeatures });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

/**
 * description: creates a band object in firestore.
 *
 * Only one of the following params is required/will be used
 * @param {object} req.data The JSON data that the band document will be created with
 *
 * @return { Band } returns band Object if creation was succesful or undefined
 */
const createBandForCurrentUser = async (req) => {
  try {
    if (!req || !req.data) {
      throw "No data found in request object or no request object";
    }
    // getAuth().currentUser.uid
    const urlToQuery = `${baseUrl}/band`;
    const axiosResponse = await axios.post(urlToQuery, req.data);
    const { data } = axiosResponse;
    return formatBand(data);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const createBandWithRandomId = async (req) => {
  try {
    if (!req || !req.data) {
      throw "No data found in request object or no request object";
    }
    // getAuth().currentUser.uid
    const urlToQuery = `${baseUrl}/band`;
    const axiosResponse = await axios.post(urlToQuery, req.data);
    const { data } = axiosResponse;
    return formatBand(data);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

/**
 * description: Checks if a band exists via bandName
 *
 * Only one of the following params is required/will be used
 * @param {string} bandName The name of the band that is being checked for
 *
 * @return { boolean } returns true if the band exists and false if not
 */
const checkIfPageNameExists = async (pageName) => {
  const band = await getBand({ pageName });
  if (band) {
    return true;
  }
  return false;
};

const addUserToBand = async (bandId, email, role, receiveTexts = false) => {
  try {
    const userEndpoint = `${baseUrl}/user-exists?email=${email}`;
    const axiosResponse = await axios.get(userEndpoint);
    const { data } = axiosResponse;
    if (data) {
      // ID of user
      const { ID } = data;
      const bandEndpoint = `${baseUrl}/band-member`;
      const bandMemberData = {
        userId: ID,
        bandId,
        role,
        receiveTexts,
      };
      await axios.post(bandEndpoint, bandMemberData);
    } else {
      throw new Error("No matching user with that email");
    }
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const removeUserFromBand = async (bandId, userId) => {
  try {
    // get user and band relationship
    const bandMemberEndpoint = `${baseUrl}/band-member?bandId=${bandId}&userId=${userId}`;
    const axiosResponse = await axios.get(bandMemberEndpoint);
    const { data } = axiosResponse;
    if (data) {
      // delete user and band relationship
      const { ID } = data;
      const bandMemberEndpoint = `${baseUrl}/band-member`;
      await axios.delete(bandMemberEndpoint, { ID });
    } else {
      throw new Error("No matching relationship with that userId and bandId");
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateSocialForBand = async (bandId, socialId, socialData) => {
  try {
    const bandSocialEndpoint = `${baseUrl}/band-social`;
    const bandSocialData = {
      bandId,
      ID: socialId,
      ...socialData,
    };
    await axios.patch(bandSocialEndpoint, bandSocialData);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateSocialsForBand = async (bandId, socialsDict) => {
  try {
    const endpoint = `${baseUrl}/band-socials`;
    await axios.patch(endpoint, { bandId, socialsDict });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateMemberInBand = async (bandId, userId, updateData) => {
  try {
    // get user and band relationship
    const bandMemberEndpoint = `${baseUrl}/band-member?bandId=${bandId}&userId=${userId}`;
    const axiosResponse = await axios.get(bandMemberEndpoint);
    const { data } = axiosResponse;
    if (data) {
      // delete user and band relationship
      const { ID } = data;
      updateData.ID = ID;
      const bandMemberEndpoint = `${baseUrl}/band-member`;
      await axios.patch(bandMemberEndpoint, updateData);
    } else {
      throw new Error("No matching relationship with that userId and bandId");
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

// To be done later

/**
 * description: gets all Bands and transforms them appropriately for super dashboard
 *
 * @return { Band[] }
 */
const getAllBands = async () => {
  try {
    const urlToQuery = `${baseUrl}/admin/all-bands`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data.map(formatBand);
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getStatsForBand = async (bandId) => {
  try {
    if (!bandId) {
      throw new Error("No bandId found");
    }

    const bandStatsUrl = `${baseUrl}/band/stats?ID=${bandId}`;
    const axiosResponse = await axios.get(bandStatsUrl);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

const getTopSongsForBand = async (bandId, limit = 3) => {
  try {
    if (!bandId) {
      throw new Error("No bandId found");
    }

    const bandStatsUrl = `${baseUrl}/band/top-songs?ID=${bandId}&limit=${limit}`;
    const axiosResponse = await axios.get(bandStatsUrl);
    const { data } = axiosResponse;
    return data || [];
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

/**
 * description: Gets all Band Objects for dashboard and resturns them
 *
 * @return { Band[] } returns band Object if creation was succesful or undefined
 */
const getAllBandsForDash = async () => {
  try {
    const bandStatsUrl = `${baseUrl}/bands/stats`;
    const axiosResponse = await axios.get(bandStatsUrl);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export {
  getBand,
  getAllBands,
  updateBand,
  createBandForCurrentUser,
  createBandWithRandomId,
  getAllBandsForDash,
  checkIfPageNameExists,
  getAllBandsForUser,
  addUserToBand,
  removeUserFromBand,
  updateMemberInBand,
  updateSocialForBand,
  getStatsForBand,
  getTopSongsForBand,
  updateSocialsForBand,
  deleteBand,
  updateBandBetaStatus,
};
