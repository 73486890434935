const axios = require("axios");
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

const getLivecastState = async (gigId, forceRefresh = false) => {
  try {
    if (!gigId) {
      throw new Error("No gigId provided");
    }
    const cachedValue = state.currentLivecastState;
    if (cachedValue?.gigId === gigId && !forceRefresh) {
      return cachedValue.value;
    }

    const axiosResponse = await axios.get(`${baseUrl}/livecast?gigId=${gigId}`);
    const { data } = axiosResponse;

    nonPersistentStore.commit("addOrUpdateLivecastStateData", {
      gigId,
      value: data,
    });

    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const sendImageToLivecast = async (gigId, shoutoutId) => {
  try {
    const axiosEndpoint = `${baseUrl}/livecast/select-shoutout`;
    const axiosResponse = await axios.post(axiosEndpoint, {
      gigId,
      currentShoutoutId: shoutoutId,
    });
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export { getLivecastState, sendImageToLivecast };
