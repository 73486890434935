let config;
let publishableKey;
let apiBaseUrl;
let photoStorageUrl;
const { io } = require("socket.io-client");
const axios = require("axios");

if (window.location.origin === "http://localhost:8080") {
  config = {
    apiKey: "AIzaSyCml2z-bXqQtBQ2Q-VCeStvATzZYxiPKNM",
    authDomain: "juke-dev-334b4.firebaseapp.com",
    databaseURL: "https://juke-dev-334b4.firebaseio.com",
    projectId: "juke-dev-334b4",
    storageBucket: "juke-dev-334b4.appspot.com",
    messagingSenderId: "916059039610",
    appId: "1:916059039610:web:d03bbac3908dfd73d298c3",
    measurementId: "G-4FM4ZKMMVX",
  };
  apiBaseUrl = "http://localhost:3000";
  publishableKey = "pk_test_scvEcCoQ3OVDQctaewC5ktCk00vs7Px6YG";
  photoStorageUrl = "https://storage.googleapis.com/juke-dev-334b4.appspot.com";
  //photoStorageUrl =
  //  "https://storage.googleapis.com/juke-live-de04d.appspot.com";
} else if (window.location.origin === "https://dev.juke.band") {
  console.log("Staging");
  config = {
    apiKey: "AIzaSyCml2z-bXqQtBQ2Q-VCeStvATzZYxiPKNM",
    authDomain: "juke-dev-334b4.firebaseapp.com",
    databaseURL: "https://juke-dev-334b4.firebaseio.com",
    projectId: "juke-dev-334b4",
    storageBucket: "juke-dev-334b4.appspot.com",
    messagingSenderId: "916059039610",
    appId: "1:916059039610:web:d03bbac3908dfd73d298c3",
    measurementId: "G-4FM4ZKMMVX",
  };
  apiBaseUrl = "https://staging.jukeremote.com";
  publishableKey = "pk_test_scvEcCoQ3OVDQctaewC5ktCk00vs7Px6YG";
  photoStorageUrl = "https://storage.googleapis.com/juke-dev-334b4.appspot.com";
} else {
  config = {
    apiKey: "AIzaSyDaRimJyanyb2C6tLfqXIH6ZdD9ykvMVm8",
    authDomain: "juke-live-de04d.firebaseapp.com",
    databaseURL: "https://juke-live-de04d.firebaseio.com",
    projectId: "juke-live-de04d",
    storageBucket: "juke-live-de04d.appspot.com",
    messagingSenderId: "171665074701",
    appId: "1:171665074701:web:27fc59470ac51c32b54344",
    measurementId: "G-J1QEVZ1CQE",
  };
  apiBaseUrl = "https://jukeremote.com";
  publishableKey = "pk_live_0afhGmhxmxGzjDY0jc8l9ObQ00uuVdU0vs";
  photoStorageUrl =
    "https://storage.googleapis.com/juke-live-de04d.appspot.com";
}

let socket = io.connect(apiBaseUrl, {
  transports: ["websocket"],
  secure: true,
});

const originalTrack = window.analytics.track;
let replacedTrack = false;

if (apiBaseUrl.includes("localhost") || apiBaseUrl.includes("staging")) {
  //socket.onAny((event, ...args) => {
  //console.log(event, args);
  //});
  socket.on("connect", () => {
    console.log("successfully connected to socket");
  });
  socket.on("connect_error", (error) => {
    console.log("error connecting to socket", error);
  });
  setInterval(() => {
    console.log("connection status", socket.connected, socket.id);
  }, 10 * 1000);
}

socket.on("connect", () => {
  if (!replacedTrack) {
    replacedTrack = true;
    window.analytics.track = (...args) => {
      const { nonPersistentStore } = require("./store/store");
      const [eventName, eventProperties] = args;
      if (nonPersistentStore?.state) {
        const { uniqueId, currentUser } = nonPersistentStore.state;
        if (eventProperties) {
          eventProperties.uniqueSessionId = uniqueId || null;
          eventProperties.userId = currentUser?.ID || null;
          axios.post(`${apiBaseUrl}/user-events`, {
            eventName,
            ...eventProperties,
          });
          originalTrack(eventName, eventProperties);
        } else {
          axios.post(`${apiBaseUrl}/user-events`, {
            eventName,
            ...eventProperties,
          });
          originalTrack(eventName, eventProperties);
        }
      } else {
        axios.post(`${apiBaseUrl}/user-events`, {
          eventName,
          ...eventProperties,
        });
        originalTrack(eventName, eventProperties);
      }
    };
  }
});

socket.on("disconnect", () => {
  const timeout = setTimeout(() => {
    if (socket.connected) {
      clearTimeout(timeout);
      return;
    }
    socket = io.connect(apiBaseUrl, {
      transports: ["websocket"],
      secure: true,
    });
  }, 5000);
});

const sharedConstants = {
  firestoreConfig: config,
  publishableKey: publishableKey,
  apiBaseUrl: apiBaseUrl,
  photoStorageUrl: photoStorageUrl,
  socket: socket,
};

export default sharedConstants;
