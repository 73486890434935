import axios from "axios";

import {
  formatEvent,
  formatEventOption,
  formatEventTransaction,
} from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

const getEvent = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object");
    }
    let { eventId, pageName, forceRefresh = false } = req;

    if (!eventId && !pageName) {
      throw new Error("No eventId or pageName");
    }

    if (
      state.currentEvent &&
      state.currentEvent.ID === eventId &&
      !forceRefresh
    ) {
      return state.currentEvent;
    }

    let eventUrl;

    if (eventId) {
      eventUrl = `${baseUrl}/event?ID=${eventId}`;
    } else if (pageName) {
      eventUrl = `${baseUrl}/event?pageName=${pageName}`;
    }

    const axiosResponse = await axios.get(eventUrl);
    const { data } = axiosResponse;
    if (data) {
      const formattedEvent = formatEvent(data);
      nonPersistentStore.commit("addOrUpdateEventData", formattedEvent);
      return formattedEvent;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

const updateEvent = async (eventId, updateObject) => {
  try {
    if (!eventId) {
      throw new Error("No eventId");
    }
    updateObject.ID = eventId;

    const eventEndpoint = `${baseUrl}/event`;

    await axios.patch(eventEndpoint, updateObject);

    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const createEvent = async (data) => {
  try {
    const eventEndpoint = `${baseUrl}/event`;
    const axiosResponse = await axios.post(eventEndpoint, data);
    const { data: eventData } = axiosResponse;
    const formattedEvent = formatEvent(eventData);

    return formattedEvent;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const deleteEvent = async (eventId) => {
  try {
    if (!eventId) {
      throw new Error("No eventId");
    }
    const eventEndpoint = `${baseUrl}/event`;
    await axios.delete(eventEndpoint, { ID: eventId });
  } catch (err) {
    console.log(err);
    return false;
  }
};

const addOptionToEvent = async (eventId, optionData) => {
  try {
    if (!eventId) {
      throw new Error("No eventId");
    }
    optionData.ID = eventId;

    const eventOptionEndpoint = `${baseUrl}/event-option`;
    const axiosResponse = await axios.post(eventOptionEndpoint, optionData);
    const { data: eventData } = axiosResponse;
    const formattedEventOption = formatEventOption(eventData);

    return formattedEventOption;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateOptionForEvent = async (optionId, optionData) => {
  try {
    if (!optionId) {
      throw new Error("No optionId");
    }
    optionData.ID = optionId;

    const eventOptionEndpoint = `${baseUrl}/event-option`;
    await axios.patch(eventOptionEndpoint, optionData);

    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const removeOptionFromEvent = async (optionId, eventId) => {
  try {
    if (!optionId) {
      throw new Error("No optionId");
    }
    if (!eventId) {
      throw new Error("No eventId");
    }
    const eventOptionEndpoint = `${baseUrl}/event-option`;
    await axios.delete(eventOptionEndpoint, { ID: optionId, eventId: eventId });
  } catch (err) {
    console.log(err);
    return false;
  }
};

const createEventTransaction = async (transactionObject) => {
  try {
    const eventTransactionEndpoint = `${baseUrl}/event-transaction`;
    await axios.post(eventTransactionEndpoint, transactionObject);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getMostRecentEventTransactionForCustomer = async (gigEventId) => {
  const uniqueSessionId = nonPersistentStore.state.uniqueId;
  try {
    if (!gigEventId) {
      throw new Error("No gigEventId");
    }
    const axiosEndpoint = `${baseUrl}/event-transaction?uniqueSessionId=${uniqueSessionId}&gigEventId=${gigEventId}`;
    const axiosResponse = await axios.get(axiosEndpoint);
    const { data } = axiosResponse;
    if (data) {
      const formattedEventTransaction = formatEventTransaction(data);
      return formattedEventTransaction;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getAllEventTransactions = async (gigId, forceRefresh = false) => {
  try {
    if (!gigId) {
      throw new Error("No gigId");
    }
    if (state.currentEventTransactions?.gigId === gigId && !forceRefresh) {
      return state.currentEventTransactions.value;
    }
    const eventTransactionEndpoint = `${baseUrl}/event-transactions/grouped?gigId=${gigId}`;
    const axiosResponse = await axios.get(eventTransactionEndpoint);
    const { data } = axiosResponse;

    nonPersistentStore.commit("addOrUpdateEventTransactionsData", {
      gigId,
      value: data,
    });
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getAllGigEvents = async (gigId, forceRefresh = false) => {
  try {
    if (!gigId) {
      throw new Error("No gigId");
    }
    if (state.currentGigEvents?.gigId == gigId && !forceRefresh) {
      return state.currentGigEvents.values;
    }
    let axiosEndpoint = `${baseUrl}/gig-events?gigId=${gigId}`;

    if (forceRefresh) {
      axiosEndpoint += `&ignoreCache=true&initiatedAt=${new Date().toJSON()}`;
    }

    const axiosResponse = await axios.get(axiosEndpoint);
    const { data } = axiosResponse;

    nonPersistentStore.commit("addOrUpdateGigEventsData", {
      gigId,
      values: data,
    });

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getAllEventsForBand = async (bandId) => {
  try {
    const axiosEndpoint = `${baseUrl}/events?bandId=${bandId}`;
    const axiosResponse = await axios.get(axiosEndpoint);
    const { data } = axiosResponse;
    const events = data.map(formatEvent);
    return events;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const addEventToGig = async (gigId, eventId, bandId) => {
  try {
    if (!gigId || !eventId) {
      throw new Error("No gigId or eventId");
    }
    if (!bandId) {
      throw new Error("No bandId");
    }
    const axiosEndpoint = `${baseUrl}/gig-event`;
    await axios.post(axiosEndpoint, { gigId, eventId, bandId, authId: bandId });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const removeEventFromGig = async (gigEventId) => {
  try {
    if (!gigEventId) {
      throw new Error("No gigEventId");
    }
    const axiosEndpoint = `${baseUrl}/gig-event`;
    await axios.patch(axiosEndpoint, { ID: gigEventId, hidden: true });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const switchEventOnLivecast = async (gigEventId, gigId) => {
  try {
    if (!gigEventId || !gigId) {
      throw new Error("No gigEventId or gigId");
    }
    const axiosEndpoint = `${baseUrl}/livecast/select-event`;
    const livecastState = await axios.post(axiosEndpoint, {
      currentGigEventId: gigEventId,
      gigId,
    });
    return livecastState;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateGigEvent = async (gigEventId, updateObject) => {
  try {
    if (!gigEventId) {
      throw new Error("No gigEventId");
    }
    if (!updateObject) {
      throw new Error("No updateObject");
    }
    const axiosEndpoint = `${baseUrl}/gig-event`;
    await axios.patch(axiosEndpoint, { ID: gigEventId, ...updateObject });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export {
  getEvent,
  updateEvent,
  createEvent,
  createEventTransaction,
  getAllEventTransactions,
  addOptionToEvent,
  updateOptionForEvent,
  removeOptionFromEvent,
  getAllEventsForBand,
  getAllGigEvents,
  getMostRecentEventTransactionForCustomer,
  deleteEvent,
  addEventToGig,
  removeEventFromGig,
  switchEventOnLivecast,
  updateGigEvent,
};
