import axios from "axios";

import {
  formatSongPass,
  hasSongPassForGigInLocalCache,
} from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

const getSongPassesForGig = async (gigId, forceRefresh) => {
  try {
    let axiosEndpoint = `${baseUrl}/song-passes/auth?gigId=${gigId}`;

    if (forceRefresh) {
      axiosEndpoint += `&initiatedAt=${new Date().toJSON()}`;
    }

    const axiosResponse = await axios.get(axiosEndpoint);
    const axiosData = axiosResponse.data;

    return axiosData.map(formatSongPass);
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getSongPassesForCustomer = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object provided");
    }
    const { customerEmail } = req;

    const uniqueSessionId = state.uniqueId;

    if (!customerEmail && !uniqueSessionId) {
      throw new Error("No customerEmail or uniqueSessionId provided");
    }

    let axiosEndpoint;

    if (customerEmail) {
      axiosEndpoint = `${baseUrl}/song-passes?customerEmail=${customerEmail}`;
    } else if (uniqueSessionId) {
      axiosEndpoint = `${baseUrl}/song-passes?uniqueSessionId=${uniqueSessionId}`;
    }

    const axiosResponse = await axios.get(axiosEndpoint);
    const axiosData = axiosResponse.data;

    if (axiosData) {
      const localSongPasses = nonPersistentStore.state.localSongPasses.slice();
      const myHash = {};
      for (const songPass of localSongPasses) {
        myHash[songPass.gigId] = songPass;
      }
      for (const songPass of axiosData) {
        if (!(songPass.gigId in myHash)) {
          localSongPasses.push(songPass);
        }
      }
      nonPersistentStore.commit("setLocalSongPasses", localSongPasses);
    }
    return axiosData;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const checkIfCurrentUserHasSongPassForGig = async (gigId) => {
  try {
    const inititalCheck = hasSongPassForGigInLocalCache(gigId);
    if (inititalCheck) {
      return true;
    } else {
      await getSongPassesForCustomer({ key: "value" });
      const secondCheck = hasSongPassForGigInLocalCache(gigId);
      return secondCheck;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export {
  checkIfCurrentUserHasSongPassForGig,
  getSongPassesForGig,
  getSongPassesForCustomer,
};
