<template lang="pug">
    v-dialog(v-model='display')
        v-dialog(v-model='submittingQuestion')
            v-card
                v-card-title Submit a question.
                v-card-text
                    v-textarea(v-model='questionTitle' placeholder='Enter your question' outlined hide-details)
                v-card-actions
                    .centerButton
                        v-btn(:disabled='questionTitle.length === 0' @click='submitQuestion') Submit
        v-card.popup-box
            .myCol
                .mySearchRow
                    v-text-field(v-model='search' hide-details prepend-inner-icon="mdi-magnify" placeholder='Search FAQs' clearable)
                .expansionCol
                    v-expansion-panels(accordion)
                        v-expansion-panel(v-for="item, i in filteredFAQs" :key="i")
                            v-expansion-panel-header
                                | {{ item.title }}
                            v-expansion-panel-content
                                | {{ item.response }}
                .submitAQuestionRow
                    v-btn(@click='startSubmission') Submit a question
</template>

<script>
import { addFaq, getAllFaqs } from "../services/firebaseService";

export default {
  name: "FAQDialog",
  data: () => ({
    display: false,
    faqs: [],
    search: "",
    submittingQuestion: false,
    questionTitle: "",
  }),
  async mounted() {},
  computed: {
    filteredFAQs() {
      const faqs = this.faqs;
      if (!faqs || faqs.length === 0) {
        return [];
      } else {
        const lower = this.search ? this.search.toLowerCase() : "";

        if (!lower || lower.length === 0) {
          return faqs.filter((elem) => elem.hidden === false);
        }

        return faqs.filter((elem) => {
          const title = elem.title.toLowerCase();
          const hidden = elem.hidden;

          return title.indexOf(lower) !== -1 && hidden === false;
        });
      }
    },
  },
  methods: {
    async submitQuestion() {
      await addFaq(this.questionTitle);
      this.submittingQuestion = false;
      this.questionTitle = "";
    },
    startSubmission() {
      this.submittingQuestion = true;
    },
    openDialog() {
      getAllFaqs().then((faqs) => {
        this.faqs = faqs;
      });
      this.display = true;
      this.questionTitle = "";
      this.submittingQuestion = false;
    },
    closeDialog() {
      this.display = false;
    },
  },
};
</script>

<style scoped lang="scss">
.popup-box {
  background-color: white;
  border-width: 5px;
  top: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 10px;
}
.myCol {
  display: flex;
  flex-direction: column;
  width: auto;
  background: white;
  height: 500px;
  padding: 16px;
  width: 300px;
}
.expansionCol {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
}
.expansionCol::-webkit-scrollbar {
  visibility: hidden;
}
.submitAQuestionRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mySearchRow {
  margin-bottom: 5px;
}
.centerButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
</style>
