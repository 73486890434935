import axios from "axios";

import { formatResetSong } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

const getResetSongsForGig = async (gigId) => {
  try {
    if (!gigId) {
      throw new Error("No gigId provided");
    }

    const resetSongsInStore = state.currentSongResets;
    if (resetSongsInStore && resetSongsInStore.gigId === gigId) {
      return resetSongsInStore.values;
    }

    const resetSongsUrl = `${baseUrl}/reset-songs?gigId=${gigId}`;
    const axiosResponse = await axios.get(resetSongsUrl);
    const { data } = axiosResponse;

    const mappedData = data.map(formatResetSong);
    nonPersistentStore.commit("addOrUpdateSongResetsData", {
      gigId,
      values: mappedData,
    });
    return mappedData;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export { getResetSongsForGig };
