import axios from "axios";

import {
  formatGigSongBattle,
  formatSongBattle,
  formatSongBattleTransaction,
} from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

const getSongBattle = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object provided");
    }
    const { ID } = req;

    if (!ID) {
      throw new Error("No ID provided");
    }

    const axiosEndpoint = `${baseUrl}/song-battle?ID=${ID}`;
    const response = await axios.get(axiosEndpoint);
    const songBattleData = response.data;
    const formattedSongBattle = songBattleData
      ? formatSongBattle(songBattleData)
      : null;

    return formattedSongBattle;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getSongBattles = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object provided");
    }
    const { bandId } = req;

    if (bandId) {
      const axiosEndpoint = `${baseUrl}/song-battles?bandId=${bandId}`;
      const response = await axios.get(axiosEndpoint);
      const songBattlesData = response.data;
      const formattedSongBattles = songBattlesData
        ? songBattlesData.map(formatSongBattle)
        : [];
      return formattedSongBattles;
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getSongBattleTransactions = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object provided");
    }
    let groupSongBattleTransactions = true;
    const {
      bandId,
      gigId,
      forceRefresh,
      groupSongBattleTransactions: groupParam,
    } = req;
    if (groupParam === false) {
      groupSongBattleTransactions = false;
    }

    if (gigId) {
      const valueInStore = state.currentSongBattleTransactions;

      if (
        valueInStore &&
        valueInStore.gigId === gigId &&
        !forceRefresh &&
        groupSongBattleTransactions
      ) {
        return valueInStore.values;
      }

      const axiosEndpoint = groupSongBattleTransactions
        ? `${baseUrl}/song-battle-transactions/grouped?gigId=${gigId}`
        : `${baseUrl}/song-battle-transactions?gigId=${gigId}`;
      const response = await axios.get(axiosEndpoint);
      const songBattleTransactions = response.data;
      const formattedsongBattleTransactions = songBattleTransactions;

      if (groupSongBattleTransactions) {
        nonPersistentStore.commit("addOrUpdateSongBattleTransactionsData", {
          gigId,
          values: formattedsongBattleTransactions,
        });
      }

      return formattedsongBattleTransactions;
    } else if (bandId) {
      const axiosEndpoint = `${baseUrl}/song-battle-transactions?bandId=${bandId}`;
      const response = await axios.get(axiosEndpoint);
      const songBattleTransactions = response.data;
      const formattedsongBattleTransactions = songBattleTransactions
        ? songBattleTransactions.map(formatSongBattleTransaction)
        : [];
      return formattedsongBattleTransactions;
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getSongBattleTransaction = async (req) => {
  try {
    if (!req) {
      throw new Error("No request object provided");
    }
    const { uniqueSessionId, gigId } = req;

    if (uniqueSessionId && gigId) {
      const axiosEndpoint = `${baseUrl}/song-battle-transaction?uniqueSessionId=${uniqueSessionId}`;
      const response = await axios.get(axiosEndpoint);
      const songBattleTransactionData = response.data;
      const formattedsongBattleTransactionData = songBattleTransactionData
        ? formatSongBattleTransaction(songBattleTransactionData)
        : null;
      return formattedsongBattleTransactionData;
    } else {
      throw new Error("UniqueSessionId and gigId must be provided");
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

const updateSongBattle = async (songBattleId, songBattleObj) => {
  try {
    if (!songBattleId) {
      throw new Error("No songBattleId provided");
    }
    songBattleObj.ID = songBattleId;
    const axiosEndpoint = `${baseUrl}/song-battle`;
    const response = await axios.patch(axiosEndpoint, songBattleObj);
    const songBattleData = response.data;
    const formattedSongBattle = songBattleData
      ? formatSongBattle(songBattleData)
      : null;
    return formattedSongBattle;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const deleteSongBattle = async (songBattleId) => {
  try {
    if (!songBattleId) {
      throw new Error("No songBattleId provided");
    }
    const axiosEndpoint = `${baseUrl}/song-battle`;
    const response = await axios.delete(axiosEndpoint, { ID: songBattleId });
    const songBattleData = response.data;
    return songBattleData == true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const createSongBattle = async (songBattleObj) => {
  try {
    const axiosEndpoint = `${baseUrl}/song-battle`;
    const response = await axios.post(axiosEndpoint, songBattleObj);
    const songBattleData = response.data;
    const formattedSongBattle = songBattleData
      ? formatSongBattle(songBattleData)
      : null;
    return formattedSongBattle;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const createSongBattleOption = async (songBattleId, songBattleObj) => {
  try {
    const axiosEndpoint = `${baseUrl}/song-battle-option`;
    songBattleObj.songBattleId = songBattleId;
    const response = await axios.post(axiosEndpoint, songBattleObj);
    const songBattleData = response.data;
    const formattedSongBattle = songBattleData
      ? formatSongBattle(songBattleData)
      : null;
    return formattedSongBattle;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateSongBattleOption = async (
  songBattleId,
  songBattleOptionId,
  songBattleOptionObj
) => {
  try {
    if (!songBattleId) {
      throw new Error("No merchandiseId provided");
    }
    songBattleOptionObj.songBattleId = songBattleId;
    songBattleOptionObj.ID = songBattleOptionId;
    const axiosEndpoint = `${baseUrl}/song-battle-option`;
    const response = await axios.patch(axiosEndpoint, songBattleOptionObj);
    const songBattleData = response.data;
    const formattedSongBattle = songBattleData
      ? formatSongBattle(songBattleData)
      : null;
    return formattedSongBattle;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getSongBattlesForGig = async (gigId, forceRefresh = false) => {
  try {
    if (!gigId) {
      throw new Error("No gigId provided");
    }
    let axiosEndpoint = `${baseUrl}/gig-song-battles?gigId=${gigId}`;

    if (forceRefresh) {
      axiosEndpoint += "&ignoreCache=true";
    }

    const response = await axios.get(axiosEndpoint);
    const songBattlesData = response.data;
    const formattedSongBattles = songBattlesData
      ? songBattlesData.map(formatGigSongBattle)
      : [];
    return formattedSongBattles;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const addSongBattleToGig = async (obj) => {
  try {
    if (!obj) {
      throw new Error("No obj provided");
    }
    const { gigId, songBattleId, bandId } = obj;
    if (!songBattleId) {
      throw new Error("No songBattleId provided");
    }
    if (!gigId) {
      throw new Error("No gigId provided");
    }
    const axiosEndpoint = `${baseUrl}/gig-song-battle`;
    const response = await axios.post(axiosEndpoint, {
      gigId,
      songBattleId,
      bandId,
      authId: bandId,
    });
    const songBattleData = response.data;
    const formattedSongBattle = songBattleData
      ? formatGigSongBattle(songBattleData)
      : null;
    return formattedSongBattle;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const removeSongBattleFromGig = async (gigSongBattleId) => {
  try {
    if (!gigSongBattleId) {
      throw new Error("No gigSongBattleId provided");
    }
    const axiosEndpoint = `${baseUrl}/gig-song-battle`;
    const response = await axios.patch(axiosEndpoint, {
      ID: gigSongBattleId,
      hidden: true,
    });
    const songBattleData = response.data;
    return songBattleData == true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateGigSongBattle = async (gigSongBattleId, gigSongBattleObj) => {
  try {
    if (!gigSongBattleId) {
      throw new Error("No gigSongBattleId provided");
    }
    gigSongBattleObj.ID = gigSongBattleId;
    const axiosEndpoint = `${baseUrl}/gig-song-battle`;
    const response = await axios.patch(axiosEndpoint, gigSongBattleObj);
    const songBattleData = response.data;
    const formattedSongBattle = songBattleData
      ? formatGigSongBattle(songBattleData)
      : null;
    return formattedSongBattle;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const createFreeSongBattleTransaction = async (obj) => {
  try {
    const axiosEndpoint = `${baseUrl}/song-battle-transaction`;
    const data = {
      ...obj,
      createdAt: new Date(),
      votes: 1,
    };
    const response = await axios.post(axiosEndpoint, data);
    const songBattleTransactionData = response.data;
    const formattedSongBattleTransaction = songBattleTransactionData
      ? formatSongBattleTransaction(songBattleTransactionData)
      : null;
    return formattedSongBattleTransaction;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getGigSongBattle = async (gigSongBattleId) => {
  try {
    if (!gigSongBattleId) {
      throw new Error("No gigSongBattleId provided");
    }
    const axiosEndpoint = `${baseUrl}/gig-song-battle?ID=${gigSongBattleId}`;
    const response = await axios.get(axiosEndpoint);
    const songBattleData = response.data;
    const formattedSongBattle = songBattleData
      ? formatGigSongBattle(songBattleData)
      : null;
    return formattedSongBattle;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export {
  getSongBattle,
  getSongBattles,
  getSongBattleTransactions,
  getSongBattleTransaction,
  updateSongBattle,
  deleteSongBattle,
  createSongBattle,
  updateSongBattleOption,
  createSongBattleOption,
  getSongBattlesForGig,
  addSongBattleToGig,
  removeSongBattleFromGig,
  updateGigSongBattle,
  createFreeSongBattleTransaction,
  getGigSongBattle,
};
