import axios from "axios";

import { formatPayout } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
/**
 * description: Returns all of the payouts for a band
 *
 * Only one of the following params is required/will be used
 * @param {string} req.bandId The authId of a band (unique)
 *
 * @return { Payout[] } returns a payout array or [] if not sucessful
 */
const getPayouts = async (req) => {
  try {
    if (!req) {
      throw "Req not provied to getPayouts";
    }
    const { bandId } = req;

    if (!bandId) {
      throw "bandId not provided to req in getPayouts";
    }

    const payoutsEndpoint = `${baseUrl}/payouts?bandId=${bandId}`;
    const axiosResponse = await axios.get(payoutsEndpoint);
    const { data } = axiosResponse;

    return data.map(formatPayout);
  } catch (err) {
    console.log(err);
    return [];
  }
};

/**
 * description: Returns all of the payouts
 *
 * @return { Payout[] } returns a payout array or [] if not sucessful
 */
const getAllPayouts = async () => {
  try {
    const payoutsEndpoint = `${baseUrl}/admin/all-payouts`;
    const axiosResponse = await axios.get(payoutsEndpoint);
    const { data } = axiosResponse;
    return data.map(formatPayout);
  } catch (err) {
    console.log(err);
    return [];
  }
};

/**
 * description: Creates a payout object
 *
 * Only one of the following params is required/will be used
 * @param {string} req.bandId The authId of a band (unique)
 * @param {number} req.amount The amount of the payout
 * @param {Date} req.createdAt The time at which the payout was created
 * @param {string} req.paymentType The payment method used to pay
 *
 * @return { boolean } returns true if successful otherwise false
 */
const createPayout = async (req) => {
  try {
    if (!req) {
      throw "No request object provided to createPayout";
    }
    const {
      bandInfo,
      amount,
      createdAt,
      createdBy,
      paymentType,
      paymentHandle,
      notes,
    } = req;

    if (!bandInfo) {
      throw "bandInfo not in request object";
    }
    if (!amount) {
      throw "amount not in request object";
    }
    if (!createdAt) {
      throw "createdAt not in request object";
    }
    if (!paymentType) {
      throw "paymentType not in request object";
    }
    if (!paymentHandle) {
      throw "paymentHandle not in request object";
    }
    let bandId = bandInfo.bandId;
    const obj = {
      authId: bandId,
      bandId,
      amount: Math.round(100 * amount),
      createdAt,
      createdBy,
      paymentType,
      paymentHandle,
      notes,
    };

    const payoutEndpoint = `${baseUrl}/payout`;
    const axiosResponse = await axios.post(payoutEndpoint, obj);
    const { data } = axiosResponse;

    return formatPayout(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

export { getPayouts, getAllPayouts, createPayout };
