const axios = require("axios");
const { nonPersistentStore } = require("../../store/store");
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { formatShoutout } = require("./helperFunctions");
const { state } = nonPersistentStore;

const getShoutout = async (ID) => {
  try {
    if (!ID) {
      throw new Error("Missing ID");
    }

    const axiosEndpoint = `${baseUrl}/shoutout?ID=${ID}`;
    const response = await axios.get(axiosEndpoint);
    const data = response.data;
    const formattedShoutout = formatShoutout(data);
    return formattedShoutout;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getShoutouts = async (req) => {
  try {
    const {
      bandId,
      gigId,
      forceRefresh = false,
      needsAuthValues = false,
    } = req;

    let axiosEndpoint;

    if (!bandId && !gigId) {
      throw new Error("Missing bandId or gigId");
    } else if (bandId) {
      axiosEndpoint = `${baseUrl}/shoutouts?authId=${bandId}`;
    } else if (gigId) {
      const currentShoutoutsInStore = needsAuthValues
        ? state.currentShoutoutsAuth
        : state.currentShoutoutsSafe;
      if (
        currentShoutoutsInStore &&
        currentShoutoutsInStore.gigId === gigId &&
        !forceRefresh
      ) {
        return currentShoutoutsInStore.values;
      }

      axiosEndpoint = needsAuthValues
        ? `${baseUrl}/shoutouts/auth?gigId=${gigId}`
        : `${baseUrl}/shoutouts?gigId=${gigId}`;
    }

    if (forceRefresh) {
      axiosEndpoint += `&initiatedAt=${new Date().toJSON()}`;
    }

    const response = await axios.get(axiosEndpoint);
    const data = response.data;
    const formattedShoutouts = data.map((shoutout) => formatShoutout(shoutout));

    if (gigId) {
      const correctCommand = needsAuthValues
        ? "addOrUpdateShoutoutsAuthData"
        : "addOrUpdateShoutoutsSafeData";
      nonPersistentStore.commit(correctCommand, {
        gigId,
        values: formattedShoutouts,
      });
    }

    return formattedShoutouts;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const createShoutout = async (data) => {
  try {
    const axiosEndpoint = `${baseUrl}/shoutout`;
    const response = await axios.post(axiosEndpoint, data);
    const shoutout = response.data;
    const formattedShoutout = formatShoutout(shoutout);
    return formattedShoutout;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const updateShoutout = async (data) => {
  try {
    const axiosEndpoint = `${baseUrl}/shoutout`;
    const response = await axios.patch(axiosEndpoint, data);
    const shoutoutData = response.data;
    const formattedShoutout = formatShoutout(shoutoutData);
    return formattedShoutout;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export { getShoutout, getShoutouts, createShoutout, updateShoutout };
