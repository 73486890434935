<template lang="pug">
    v-dialog(v-model='dialog')
        v-card
            .MyDialog
                .helloThere
                    | Hey there!
                .giphyImage
                    img(src='https://media3.giphy.com/media/Vbtc9VG51NtzT1Qnv1/giphy.gif?cid=ecf05e47k0vma2izf4w970sr9ur5h23ibimqufnacvpbx3ht&rid=giphy.gif')
                .missingInfo
                    | It seems you're missing your phone number in your account.
                .dontWorry
                    | We won't harass you with any texts.
                    | This just allows you to use some of
                    | the app's optional notification features.
                .fixIt
                    .fixItButton(v-ripple @click='fixIt')
                        | Fix it now!
                .maybeLater
                    .maybeLaterText(@click='closeDialog')
                        | Maybe later
                
</template>

<script>
export default {
  name: "UserNumberDialog",
  data: () => ({
    dialog: false,
    user: null,
  }),
  async mounted() {},
  methods: {
    fixIt() {
      this.closeDialog();
      this.$router.push("/p/settings");
    },
    openDialog(user) {
      this.user = user;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.MyDialog {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 300px;
  padding: 16px;
  justify-content: center;
  font-family: "ArticulatCF";
}
.helloThere {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}
.missingInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  margin-top: 10px;
}
.giphyImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.dontWorry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  margin-top: 10px;
}
.fixIt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}
.fixItButton {
  width: 300px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  background: linear-gradient(241.86deg, #b305f8 5.22%, #eb3348 107.36%);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "ArticulatCF";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.3px;
  color: #ffffff;
}
.maybeLater {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.maybeLaterText {
  color: gray;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
}
</style>
