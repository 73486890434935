import axios from "axios";

import { formatSetlist } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

/**
 * description: gets a setlist for a gig (chosen > default > first)
 *
 * @param {string} req.gigId The firestore docId of a gig
 * @param {string} req.setlistId The firestore docId of a setlists
 *
 * @return { Setlist } returns a setlist object if a matching one exists or undefined if not
 */
const getSetlist = async (req) => {
  try {
    if (!req) {
      throw "No request object was provided to getSetlist";
    }
    let { setlistId, forceRefresh } = req;

    if (!setlistId) {
      throw new Error("No Parameter was provided to getSetlist");
    }

    if (
      state.currentSetlist &&
      state.currentSetlist.ID === setlistId &&
      !forceRefresh
    ) {
      return state.currentSetlist;
    }

    const setlistUrl = `${baseUrl}/setlist?ID=${setlistId}`;
    const axiosResponse = await axios.get(setlistUrl);
    const { data } = axiosResponse;
    const formattedSetlist = formatSetlist(data);
    nonPersistentStore.commit("addOrUpdateSetlistData", formattedSetlist);
    return formattedSetlist;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const getSetlistWithGigMetadata = async (req) => {
  try {
    if (!req) {
      throw "No request object was provided to getSetlist";
    }
    let { gigId, authId, forceRefresh = false } = req;

    if (!gigId) {
      throw new Error("Need to provide a gigId");
    }

    if (
      state?.currentSetlistWithGigMetadata?.gigId === gigId &&
      !forceRefresh
    ) {
      return state.currentSetlistWithGigMetadata.value;
    }

    let setlistUrl = `${baseUrl}/setlist?gigId=${gigId}&authId=${authId}`;

    if (forceRefresh) {
      setlistUrl += `&initiatedAt=${new Date().toJSON()}`;
    }

    const axiosResponse = await axios.get(setlistUrl);
    const { data } = axiosResponse;
    const formattedSetlist = formatSetlist(data);
    nonPersistentStore.commit("addOrUpdateSetlistWithGigMetadata", {
      value: formattedSetlist,
      gigId,
    });
    return formattedSetlist;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

/**
 * description: gets all setlists for a band
 *
 * @param {ref} req.authId The authId for the band who has the setlists
 *
 * @return { Setlist[] } returns a setlist array if a matching one exists or [] if not
 */
const getAllSetlistsForBand = async (req) => {
  try {
    if (!req) {
      throw "No request object was provided to getAllSetlistsForBand";
    }
    const { authId } = req;
    // set up filtering/querying method
    if (!authId) {
      throw "No authId was provided to getAllSetlistsForBand";
    }

    const setlistUrl = `${baseUrl}/setlists?bandId=${authId}`;
    const axiosResponse = await axios.get(setlistUrl);
    const { data } = axiosResponse;
    return data.map(formatSetlist);
  } catch (e) {
    console.error(e);
    return [];
  }
};

/**
 * description: gets a setlist for a gig (chosen > default > first)
 *
 * @param {object} req.data The JSON data that the band document will be updated with
 * @param {string} req.gigId The firestore docId of a gig
 * @param {string} req.setlistId The firestore docId of a setlists
 *
 * @return {boolean} returns true if update was successful or false if not
 */
const updateSetlist = async (req) => {
  try {
    if (!req || !req.data) {
      throw new Error("No data found in request object or no request object");
    }

    const { setlistId } = req;

    if (!setlistId) {
      throw new Error("No setlistId provided to updateSetlist");
    }

    req.data.ID = setlistId;

    const setlistUrl = `${baseUrl}/setlist`;
    await axios.patch(setlistUrl, req.data);

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * description: creates a setlist object in firestore.
 *
 * Only one of the following params is required/will be used
 * @param {ref} req.bandRef: A band ref to band whose setlist is being created
 * @param {object} req.data The JSON data that the band document will be created with
 *
 * @return { Setlist } returns setlist Object if creation was succesful or undefined
 */
const createSetlist = async (req) => {
  try {
    if (!req || !req.data) {
      throw "No data found in request object or no request object";
    }
    if (!req.authId) {
      throw "No authId provided to request object";
    }

    const setlistUrl = `${baseUrl}/setlist`;
    const axiosResponse = await axios.post(setlistUrl, req.data);
    const { data } = axiosResponse;
    return formatSetlist(data);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

/**
 * description: creates a gig object in firestore.
 *
 * Only one of the following params is required/will be used
 * @param {object} req.setlistId The document id for the firebase doc to be deleted
 *
 * @return { boolean } returns true if operation was succesful or false if not
 */
const deleteSetlist = async (req) => {
  try {
    if (!req) {
      throw "No request object provided";
    }
    if (!req.setlistId) {
      throw "No setlistId in request object";
    }

    const setlistUrl = `${baseUrl}/setlist`;
    await axios.delete(setlistUrl, { ID: req.setlistId });

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export {
  getSetlist,
  getAllSetlistsForBand,
  updateSetlist,
  createSetlist,
  deleteSetlist,
  getSetlistWithGigMetadata,
};
