import axios from "axios";

import { formatCustomer } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

const createCustomer = async (params) => {
  try {
    const { email, uniqueSessionId, gigId, authId, bandId } = params;

    const customerUrl = `${baseUrl}/customer`;

    const addData = {
      email,
      uniqueSessionId,
      gigId,
      authId,
      bandId,
    };

    const axiosResponse = await axios.post(customerUrl, addData);
    const { data } = axiosResponse;

    return formatCustomer(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const checkIfCustomerAlreadyHasVotedInGig = async (uniqueId, gigId) => {
  try {
    if (!uniqueId) {
      throw new Error("No uniqueId provided");
    }
    if (!gigId) {
      throw new Error("No gigId provided");
    }

    const customerUrl = `${baseUrl}/tip?gigId=${gigId}&uniqueSessionId=${uniqueId}&initiatedAt=${new Date().toJSON()}`;
    const axiosResponse = await axios.get(customerUrl);
    const { data } = axiosResponse;
    return !!data;
  } catch (err) {
    console.log(err);
    // true in this case because we want to prevent the user from voting
    return true;
  }
};

const checkIfCustomerAlreadyHasVotedInGigSongBattle = async (
  uniqueId,
  gigId,
  gigSongBattleId
) => {
  try {
    if (!uniqueId) {
      throw new Error("No uniqueId provided");
    }
    if (!gigId) {
      throw new Error("No gigId provided");
    }
    if (!gigSongBattleId) {
      throw new Error("No gigSongBattleId provided");
    }

    const customerUrl = `${baseUrl}/song-battle-transaction?gigId=${gigId}&uniqueSessionId=${uniqueId}&gigSongBattleId=${gigSongBattleId}&initiatedAt=${new Date().toJSON()}`;
    const axiosResponse = await axios.get(customerUrl);
    const { data } = axiosResponse;
    return data?.ID > 0;
  } catch (err) {
    console.log(err);
    // true in this case because we want to prevent the user from voting
    return true;
  }
};

const getAllTransactionsForCustomer = async (userId) => {
  try {
    const axiosEndpoint = `${baseUrl}/customer_transactions?userId=${userId}`;
    const axiosResponse = await axios.get(axiosEndpoint);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export {
  createCustomer,
  checkIfCustomerAlreadyHasVotedInGig,
  getAllTransactionsForCustomer,
  checkIfCustomerAlreadyHasVotedInGigSongBattle,
};
