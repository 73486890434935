import axios from "axios";

import {
  formatEmailListItem,
  formatTip,
  formatTipsGroupedForGig,
} from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const state = nonPersistentStore.state;

/**
 * description: gets all song requests for a band and sets up a firebase socket connection that keeps a state in the store updated
 *
 * @param {ref} req.gigId The firestore doc id of a gig
 *
 * @return { Request[] } returns a request array if a matching one exists or [] if not
 */
const getLiveSongRequestsForGig = async (gigId, forceRefresh = false) => {
  try {
    if (!gigId) {
      throw "gigId key not provided to getLiveSongRequestsForGig";
    }

    if (
      state.currentTipsGroupedForGig &&
      state.currentTipsGroupedForGig.gigId === gigId &&
      !forceRefresh
    ) {
      return state.currentTipsGroupedForGig;
    }

    let tipsEndpoint = `${baseUrl}/tips/grouped?gigId=${gigId}`;

    if (forceRefresh) {
      tipsEndpoint += `&initiatedAt=${new Date().toJSON()}`;
    }

    const axiosResponse = await axios.get(tipsEndpoint);
    const { data } = axiosResponse;
    const formattedData = formatTipsGroupedForGig(data);

    nonPersistentStore.commit(
      "addOrUpdateTipsGroupedForGigData",
      formattedData
    );
    return formattedData;
  } catch (e) {
    console.error(e);
    return [];
  }
};

/**
 * description: gets all song requests for a band
 *
 * @param {ref} req.bandId The firestore id of a band
 *
 * @return { Request[] } returns a request array if a matching one exists or [] if not
 */
const getAllTipsForBand = async (bandId) => {
  try {
    if (!bandId) {
      throw "bandId key not provided to getAllTipsForBand";
    }

    const tipsEndpoint = `${baseUrl}/tips?bandId=${bandId}`;
    const axiosResponse = await axios.get(tipsEndpoint);
    const { data } = axiosResponse;
    const formattedData = data.map(formatTip);
    return formattedData;
  } catch (e) {
    console.error(e);
    return [];
  }
};

/**
 * description: gets all song requests for a gig
 *
 * @param {ref} req.gId The firestore id of a gig
 *
 * @return { Request[] } returns a request array if a matching one exists or [] if not
 */
const getAllTipsForGig = async (gId) => {
  try {
    if (!gId) {
      throw "gigId key not provided to getAllTipsForGig";
    }
    if (state.currentTips && state.currentTips.gigId === gId) {
      return state.currentTips.values;
    }
    const tipsEndpoint = `${baseUrl}/tips?gigId=${gId}`;
    const axiosResponse = await axios.get(tipsEndpoint);
    const { data } = axiosResponse;
    const formattedData = data.map(formatTip);

    nonPersistentStore.commit("addOrUpdateTipsData", {
      gigId: gId,
      values: formattedData,
    });
    return formattedData;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getAllCustomersForBand = async (bandId) => {
  try {
    if (!bandId) {
      throw new Error("bandId key not provided to getAllCustomersForBand");
    }
    const emailListEndpoint = `${baseUrl}/email-list?bandId=${bandId}`;
    const axiosResponse = await axios.get(emailListEndpoint);
    const { data } = axiosResponse;
    return data.map(formatEmailListItem);
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getAllCustomersForGig = async (gigId) => {
  try {
    if (!gigId) {
      throw new Error("gigId key not provided to getAllCustomersForGig");
    }
    const emailListEndpoint = `${baseUrl}/email-list?gigId=${gigId}`;
    const axiosResponse = await axios.get(emailListEndpoint);
    const { data } = axiosResponse;
    return data.map(formatEmailListItem);
  } catch (err) {
    console.log(err);
    return [];
  }
};

const createCheckoutSession = async (req) => {
  const url = `${baseUrl}/api/stripe/create-checkout-session`;
  try {
    // req is in format of { ..., metadata: { ... } }
    // get uniqueId and currentUser from store
    const { uniqueId, currentUser } = nonPersistentStore.state;
    // add uniqueId and currentUser to metadata
    req.metadata.uniqueSessionId = uniqueId || null;
    req.metadata.userId = currentUser?.ID || null;
    req.metadata.authToken = undefined;
    const axiosResponse = await axios.post(url, req);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

const createFreeTip = async (req) => {
  const {
    bandId,
    gigId,
    songName,
    artist,
    songId,
    uniqueSessionId,
    userId,
    dedication,
    customerName,
    type,
  } = req;
  try {
    const data = {
      authId: bandId,
      amount_subtotal: 0,
      amount_total: 0,
      amount_earned: 0,
      artist,
      bandId,
      createdAt: new Date(),
      customerEmail: "",
      customerName,
      dedication: dedication || null,
      eventId: "",
      gigId,
      songId,
      songName,
      status: "unplayed",
      type: type || "request",
      votes: 1,
      uniqueSessionId: uniqueSessionId || null,
      paymentCollected: true,
      userId: userId || null,
    };
    const tipEndpoint = `${baseUrl}/tip`;
    const axiosResponse = await axios.post(tipEndpoint, data);
    const { data: tipData } = axiosResponse;
    const formattedData = formatTip(tipData);
    return formattedData;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export {
  getLiveSongRequestsForGig,
  getAllTipsForBand,
  getAllTipsForGig,
  getAllCustomersForBand,
  getAllCustomersForGig,
  createFreeTip,
  createCheckoutSession,
};
