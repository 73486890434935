import axios from "axios";

import { createEdge } from "./edgeController.js";
import { formatFollower } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

const createFollower = async (createData) => {
  try {
    const urlToQuery = `${baseUrl}/follower`;
    const axiosResponse = await axios.post(urlToQuery, createData);
    const { data } = axiosResponse;
    return formatFollower(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateFollower = async (followerId, followedId, isFollowingActive) => {
  try {
    if (!followerId || !followedId || isFollowingActive === undefined) {
      throw new Error("Missing required parameters in updateFollower");
    }
    const data = {
      followerId: followerId,
      followedId: followedId,
      isFollowingActive: isFollowingActive,
    };
    const urlToQuery = `${baseUrl}/follower`;
    const updatedFollower = await axios.patch(urlToQuery, data);
    return updatedFollower;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const doesFollowerEntryExist = async (followerId, followedId) => {
  try {
    if (!followerId || !followedId) {
      throw new Error("Missing required parameters in doesFollowerEntryExist");
    }
    const urlToQuery = `${baseUrl}/follower?followerId=${followerId}&followedId=${followedId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const follow = async (followerId, followedId) => {
  try {
    if (!followerId || !followedId) {
      throw new Error("Missing required parameters in follow");
    }
    const followerEntry = await doesFollowerEntryExist(followerId, followedId);
    if (followerEntry && !followerEntry?.isFollowingActive) {
      await updateFollower(followerId, followedId, true);
      await createEdge(followerId, followedId, "ARTIST", "FOLLOW", {});
    } else if (!followerEntry) {
      await createFollower({
        followerId: followerId,
        followedId: followedId,
        isFollowingActive: true,
        createdAt: new Date(),
      });
      await createEdge(followerId, followedId, "ARTIST", "FOLLOW", {});
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const unfollow = async (followerId, followedId) => {
  try {
    if (!followerId || !followedId) {
      throw new Error("Missing required parameters in unfollow");
    }
    const followerEntry = await doesFollowerEntryExist(followerId, followedId);
    if (followerEntry && followerEntry?.isFollowingActive) {
      await updateFollower(followerId, followedId, false);
      await createEdge(followerId, followedId, "ARTIST", "UNFOLLOW", {});
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const unsubscribe = async (followerId, followedId) => {
  try {
    if (!followerId || !followedId) {
      throw new Error("Missing required parameters in unfollow");
    }
    const followerEntry = await doesFollowerEntryExist(followerId, followedId);
    if (followerEntry && followerEntry?.isSuperfan) {
      const data = {
        followerId: followerId,
        followedId: followedId,
      };
      const urlToQuery = `${baseUrl}/subscriptions`;
      const axiosResponse = await axios.patch(urlToQuery, data);
      await createEdge(followerId, followedId, "ARTIST", "UNSUBSCRIBE", {
        superfanNumber: followerEntry.superfanNumber,
      });
      return axiosResponse;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllFollowing = async (followerId) => {
  try {
    if (!followerId) {
      throw new Error("Missing required parameters in getAllFollowing");
    }
    const urlToQuery = `${baseUrl}/following?followerId=${followerId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllFollowers = async (followedId) => {
  try {
    if (!followedId) {
      throw new Error("Missing required parameters in getAllFollowers");
    }
    const urlToQuery = `${baseUrl}/followers?followedId=${followedId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllSubscriptions = async (followerId) => {
  try {
    if (!followerId) {
      throw new Error("Missing required parameters in getAllSubscriptions");
    }
    const urlToQuery = `${baseUrl}/subscriptions?followerId=${followerId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllSubscribers = async (followedId) => {
  try {
    if (!followedId) {
      throw new Error("Missing required parameters in getAllSubscribers");
    }
    const urlToQuery = `${baseUrl}/subscribers?followedId=${followedId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getNextAvailableSuperfanNumber = async (bandId) => {
  try {
    if (!bandId) {
      throw new Error(
        "Missing required parameters in getNextAvailableSuperfanNumber"
      );
    }
    const urlToQuery = `${baseUrl}/superfan-number/next?bandId=${bandId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return -1;
  }
};

export {
  doesFollowerEntryExist,
  follow,
  unfollow,
  unsubscribe,
  getAllFollowing,
  getAllFollowers,
  getAllSubscriptions,
  getAllSubscribers,
  getNextAvailableSuperfanNumber,
};
