import axios from "axios";

import { formatBandTip } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

const getAllBandTips = async (bandId) => {
  try {
    if (!bandId || bandId.length === 0) {
      throw new Error("Must provide a bandId to getAllBandTips");
    }
    const bandTipsUrl = `${baseUrl}/band-tips?bandId=${bandId}`;
    const axiosResponse = await axios.get(bandTipsUrl);
    const { data } = axiosResponse;
    return data.map(formatBandTip);
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getBandTipsTotal = async (bandId) => {
  try {
    if (!bandId) {
      throw new Error("No bandId provided");
    }
    const bandTips = await getAllBandTips(bandId);
    const amount_earned = bandTips.map((elem) => elem.amount_earned / 100);
    let sum = 0;

    for (const num of amount_earned) {
      sum += num;
    }

    return sum;
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export { getAllBandTips, getBandTipsTotal };
